import { Navigation } from "modules/navigation";
import ScrollToTop from "react-scroll-to-top";
import { Footer } from "modules/layout";
import CookieConsent from "react-cookie-consent";
import { FormattedMessage, useIntl } from "react-intl";

export const AppLayout: React.FC = ({ children }) => {
  const intl = useIntl();

  const handleAccept = () => {
    const commentStart = document.createComment(" Google Tag Manager ");
    const commentEnd = document.createComment(" End Google Tag Manager ");
    const element = document.createElement("script");

    element.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';j.async=true; j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-MH65ZF9');`;
    document.body.appendChild(commentStart);
    document.body.appendChild(element);
    document.body.appendChild(commentEnd);
  };

  return (
    <>
      <div className="wrapper">
        <div className="inner">
          <Navigation />
          <main className="main">
            {children}
          </main>
          <div className="scroll-to-top-wrapper">
            <ScrollToTop smooth top={500} />
          </div>
          <Footer />
        </div>
      </div>
      <CookieConsent
        buttonText={intl.formatMessage({ id: "cookies.povolit" })}
        declineButtonText={intl.formatMessage({ id: "cookies.zakazat" })}
        ariaAcceptLabel={intl.formatMessage({ id: "cookies.povolit" })}
        ariaDeclineLabel={intl.formatMessage({ id: "cookies.zakazat" })}
        cookieName="cookie_consent"
        enableDeclineButton
        flipButtons
        disableStyles
        onAccept={handleAccept}
      >
        <FormattedMessage id="cookies.kliknutim" /> <strong><FormattedMessage id="cookies.povolit" /></strong> <FormattedMessage id="cookies.text" />
      </CookieConsent>
    </>
  );
};
