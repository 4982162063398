import { AppLanguage } from "const";
import { NavLink, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { appStrings } from "..";

export const LanguageSwitcher: React.FC = () => {
  const { pathname } = useLocation();
  const { locale, messages } = useIntl();

  function getMatchingRoute(language: string) {
    const route = pathname.replace(/^\/[a-z]{2}\//, "/");
    const routeKey = Object.keys(messages).find((key) => messages[key] === route);
    const matchingRoute = appStrings[language][routeKey];

    return matchingRoute ? `/${language}${matchingRoute}` : `/${language}`;
  }

  return (
    <div className="menu-lang">
      <ul>
        {Object.keys(AppLanguage).map((lang) => {
          const route = getMatchingRoute(AppLanguage[lang]);

          return (
            <li key={lang}>
              <NavLink
                to={route}
                title={AppLanguage[lang]}
                className={locale === AppLanguage[lang] ? "active" : ""}
              >
                {AppLanguage[lang] === "cs" ? "cz" : AppLanguage[lang]}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
