export const replaceSpecialChars = (str: any) => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .replace(/ /g, "-");
};

export const Accordion = (event: any) => {
  document.querySelectorAll(".accordion span").forEach(span => {
    span.classList.remove("active");
  });

  const panel = event.target.nextElementSibling;
  if (panel.style.maxHeight) {
    panel.style.maxHeight = null;
  } else {
    event.target.classList.add("active");
    document.querySelectorAll(".panel").forEach(p => { // Změna názvu proměnné
      p.removeAttribute("style");
    });
    panel.style.maxHeight = panel.scrollHeight + 32 + "px";
  }

  setTimeout(() => {
    const bodyRect = document.body.getBoundingClientRect(),
        elemRect = event.target.getBoundingClientRect(),
        offset = elemRect.top - bodyRect.top;

    window.scrollTo({
      top: offset - document.getElementsByTagName("header")[0].offsetHeight - 32,
      behavior: "smooth",
    });
  }, 300);
};

export function obfuscateEmail(email: any) {
  return email.replace("@", "\u0040");
}
