import { Link, NavLink, useLocation } from "react-router-dom";
import Logo from "../../../themes/frontend/img/logo.svg";
import LogoWhite from "../../../themes/frontend/img/logo-white.svg";
import { FloatingWhatsApp } from "./FloatingWhatsApp/FloatingWhatsApp";
import { AppRoute, AppRouteTitles } from "const";
import { FormattedMessage, useIntl } from "react-intl";
import { LanguageSwitcher } from "modules/i18n";

export const Navigation: React.FC = () => {
  const { pathname } = useLocation();
  const { formatMessage, locale } = useIntl();
  const intl = useIntl();

  function localizeRouteKey(path: string) {
    return `/${locale}` + formatMessage({ id: path });
  }

  const onClick = () => {
    document.getElementsByTagName("body")[0].classList.toggle("menu-open");
  };

  return (
    <header itemScope itemType="https://schema.org/WPHeader">
      {pathname !== "/cs/" && pathname !== "/en/" && pathname !== "/de/" ? <a href="tel:+420728313090" title="+420 728 313 090"><span>Objednávejte se na telefonním čísle </span>+420 728 313 090</a> : ""}
      <nav itemScope itemType="https://schema.org/SiteNavigationElement">
        <div className="container">
          <span className="menu-open" onClick={onClick}>
            <span className="dashes">
              <span className="dash" />
              <span className="dash" />
              <span className="dash" />
              <span className="dash" />
              <span className="dash" />
            </span>
          </span>
          <h1 className="logo">
            <Link to={localizeRouteKey(AppRoute.Home)} title={intl.formatMessage({ id: "home.permanent" }) + " Veronika"}>
              <img className="logo-black" src={Logo} alt={intl.formatMessage({ id: "home.permanent" }) + " Veronika"} width="231" height="150" />
              <img className="logo-white" src={LogoWhite} alt={intl.formatMessage({ id: "home.permanent" }) + " Veronika"} width="231" height="150" />
            </Link>
            <span className="sr-only"><FormattedMessage id="home.permanent" /> Veronika</span>
          </h1>
          <div className="buttons">
            <div id="instagramButton">
              <a href="https://instagram.com/permanentnimakeupveronika" title={"Instagram " + intl.formatMessage({ id: "home.permanent" }) + " Veronika"} target="_blank" rel="noreferrer">Instagram <FormattedMessage id="home.permanent" /> Veronika</a>
            </div>
            <div id="facebookButton">
              <a href="https://facebook.com/100035014675032" title={"Facebook " + intl.formatMessage({ id: "home.permanent" }) + " Veronika"} target="_blank" rel="noreferrer">Facebook <FormattedMessage id="home.permanent" /> Veronika</a>
            </div>
            <div id="WAButton">
              <FloatingWhatsApp phoneNumber="+420728313090" accountName="Veronika" statusMessage={intl.formatMessage({ id: "wa.status" })} chatMessage={intl.formatMessage({ id: "wa.chat" })} placeholder={intl.formatMessage({ id: "wa.placeholder" })} />
            </div>
            <div id="phoneButton">
              <a href="tel:+420728313090" title="+420 728 313 090">+420 728 313 090</a>
            </div>
          </div>
          <div className="menu-wrapper">
            <div className="menu">
              <div className="menu-main">
                <ul>
                  {Object.keys(AppRoute).filter(item => item !== "FormularOdeslan").map((elem) => (
                    <li key={elem}>
                      <NavLink
                        exact
                        activeClassName="active"
                        to={localizeRouteKey(AppRoute[elem])}
                        title={formatMessage({ id: AppRouteTitles.get(AppRoute[elem]) || "" })}
                      >
                        {formatMessage({ id: AppRouteTitles.get(AppRoute[elem]) || "" })}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
              <LanguageSwitcher />
              <div className="designer">
                <p>&copy; 2017 - {new Date().getFullYear()} <FormattedMessage id="home.permanent" /> Veronika</p>
                <p><FormattedMessage id="data.jednicky" /> <a href="https://www.core1.agency" title={intl.formatMessage({ id: "data.jednicky" }) + " core1"} target="_blank" rel="noreferrer"><FormattedMessage id="data.jednicky" /> core1</a></p>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};
