import { Meta, Images, SectionContent, Arrow, Gallery, replaceSpecialChars, Accordion } from "modules/layout";
import { FormattedMessage, useIntl } from "react-intl";
import { Image } from "modules/layout";

export const NastrelovaniNausnic: React.FC = () => {
  const intl = useIntl();

  return (
    <>
      <Meta title={intl.formatMessage({ id: "nastrelovaninausnic.title" })} description={intl.formatMessage({ id: "nastrelovaninausnic.description" })} /> 
      <section className="padding">
        <Images />
        <SectionContent heading={intl.formatMessage({ id: "nastrelovaninausnic.title" })} />
        <Arrow url={"#"+replaceSpecialChars(intl.formatMessage({ id: "nastrelovaninausnic.title" }))} text={intl.formatMessage({ id: "nastrelovaninausnic.title" })} />
      </section>
      <section id={replaceSpecialChars(intl.formatMessage({ id: "nastrelovaninausnic.title" }))} className="padding">
        <div className="container">
          <div className="section-content right padding" data-title={intl.formatMessage({ id: "data.dulezite" })}>
            <h2><FormattedMessage id="nastrelovaninausnic.title" /> Estelle</h2>
            <ul>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="nastrelovaninausnic.co" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="nastrelovaninausnic.co1" /></li>
                  <li><FormattedMessage id="nastrelovaninausnic.co2" /></li>
                  <li><FormattedMessage id="nastrelovaninausnic.co3" /></li>
                  <li><FormattedMessage id="nastrelovaninausnic.co4" /></li>
                  <li className="has-img">
                    <Image clas="auto" src="estelle" alt="Estelle" width={200} height={309} lazy={false} />
                  </li>
                </ul>
              </li>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="nastrelovaninausnic.scim" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="nastrelovaninausnic.scim1" /></li>
                  <li><FormattedMessage id="nastrelovaninausnic.scim2" /></li>
                  <li><FormattedMessage id="nastrelovaninausnic.scim3" /></li>
                  <li><FormattedMessage id="nastrelovaninausnic.scim4" /></li>
                  <li><FormattedMessage id="nastrelovaninausnic.scim5" /></li>
                  <li><FormattedMessage id="nastrelovaninausnic.scim6" /></li>
                  <li className="has-img">
                    <Image clas="auto" src="swarovski" alt="Swarovski Elements" width={315} height={125} lazy={false} />
                  </li>
                </ul>
              </li>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="nastrelovaninausnic.jak" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="nastrelovaninausnic.jak1" /></li>
                  <li><FormattedMessage id="nastrelovaninausnic.jak2" /></li>
                  <li><FormattedMessage id="nastrelovaninausnic.jak3" /></li>
                  <li><FormattedMessage id="nastrelovaninausnic.jak4" /></li>
                  <li><FormattedMessage id="nastrelovaninausnic.jak5" /></li>
                  <li><FormattedMessage id="nastrelovaninausnic.jak6" /></li>
                </ul>
              </li>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="nastrelovaninausnic.kdy" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="nastrelovaninausnic.kdy1" /></li>
                  <li><FormattedMessage id="nastrelovaninausnic.kdy2" /></li>
                  <li><FormattedMessage id="nastrelovaninausnic.kdy3" /></li>
                  <li><FormattedMessage id="nastrelovaninausnic.kdy4" /></li>
                </ul>
              </li>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="nastrelovaninausnic.pro" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="nastrelovaninausnic.kontraindikace1" /></li>
                  <li><FormattedMessage id="nastrelovaninausnic.kontraindikace2" /></li>
                  <li><FormattedMessage id="nastrelovaninausnic.kontraindikace3" /></li>
                  <li><FormattedMessage id="nastrelovaninausnic.kontraindikace4" /></li>
                  <li><FormattedMessage id="nastrelovaninausnic.kontraindikace5" /></li>
                  <li><FormattedMessage id="nastrelovaninausnic.kontraindikace6" /></li>
                  <li><FormattedMessage id="nastrelovaninausnic.kontraindikace7" /></li>
                </ul>
              </li>
              <li className="accordion has-pricelist">
                <span onClick={Accordion}><FormattedMessage id="nastrelovaninausnic.kolik" /></span>
                <ul className="panel">
                  <li className="pricelist">
                    <ul className="pricelist-inner">
                      <li className="pricelist-row">
                        <span><FormattedMessage id="nastrelovaninausnic.anti1" /><br /><FormattedMessage id="nastrelovaninausnic.swarovski" /></span>
                        <span><strong><FormattedMessage id="nastrelovaninausnic.anti2" /></strong></span>
                      </li>
                      <li className="pricelist-row">
                        <span><FormattedMessage id="nastrelovaninausnic.zlato1" /><br /><FormattedMessage id="nastrelovaninausnic.swarovski" /></span>
                        <span><strong><FormattedMessage id="nastrelovaninausnic.zlato2" /></strong></span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <br /><br />
            <h3><FormattedMessage id="data.neprehlednete" /></h3>
            <ul>
              <li><FormattedMessage id="data.neprehlednete5" /></li>
            </ul>
          </div>
        </div>
      </section>  
      <Gallery lower="nastrelovani-nausnic" text={intl.formatMessage({ id: "nastrelovaninausnic.title" })} count={11} />
    </>
  );
};
