export enum AppRoute {
  Home = "routes.home",
  Omne = "routes.omne",
  PudroveOboci = "routes.pudroveoboci",
  Rty = "routes.rty",
  OcniLinky = "routes.ocnilinky",
  MiniAquarelleTattoo = "routes.miniaquarelletattoo",
  OdstraneniPMU = "routes.odstranenipmu",
  NastrelovaniNausnic = "routes.nastrelovaninausnic",
  Cenik = "routes.cenik",
  DarkovePoukazy = "routes.darkovepoukazy",
  Kontakt = "routes.kontakt",
  FormularOdeslan = "routes.formularodeslan"
}

export const AppRouteTitles = new Map([
  [AppRoute.Home, "home.title"],
  [AppRoute.Omne, "omne.title"],
  [AppRoute.PudroveOboci, "pudroveoboci.title"],
  [AppRoute.Rty, "rty.title"],
  [AppRoute.OcniLinky, "ocnilinky.title"],
  [AppRoute.MiniAquarelleTattoo, "miniaquarelletattoo.title"],
  [AppRoute.OdstraneniPMU, "odstranenipmu.title"],
  [AppRoute.NastrelovaniNausnic, "nastrelovaninausnic.title"],
  [AppRoute.Cenik, "cenik.title"],
  [AppRoute.DarkovePoukazy, "darkovepoukazy.title"],
  [AppRoute.Kontakt, "kontakt.title"],
  [AppRoute.FormularOdeslan, "formularodeslan.title"]
]);
