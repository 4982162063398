import { Meta, Images, SectionContent, Arrow, replaceSpecialChars } from "modules/layout";
import { FormattedMessage, useIntl } from "react-intl";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { useForm, MultipleFieldErrors } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import axios from "axios";

export const Kontakt: React.FC = () => {
  const intl = useIntl();

  const history = useHistory();

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
        criteriaMode: "all"
      });
  const onSubmit = (data: any) => {
    try {
      const formData = new FormData();
      formData.append("sender_domain", "permanentnimakeupveronika.cz"); // doména, ze které se posílá email
      formData.append("api_key", process.env.REACT_APP_API); // klíč k API, jako heslo
      formData.append("from", "info@permanentnimakeupveronika.cz"); // od koho mail bude
      formData.append("fromname", data.name); // jméno od koho je 
      formData.append("to", "jakub@core1.agency"); // email komu
      formData.append("toname", "Permanentní make-up Veronika"); // jméno adresáta
      formData.append("subject", "Nová zpráva z formuláře"); // předmět
      formData.append("body", "Dobrý den,<br>z Vašeho webu byl právě odeslán formulář:<br><br>Jméno: " + data.name + "<br>Telefon: " + data.phone + "<br>Zpráva: " + data.message); // tělo emailu
      formData.append("htmlformat", "1");
      axios.post("https://rmail.core1.agency/send_mail", formData); // pošle request na api
    } catch (error) {
      console.error("Chyba při odesílání formuláře:", error);
    }
    Cookies.set("formSubmitted", "1");
    history.push(replaceSpecialChars(intl.formatMessage({ id: "formularodeslan.title" })));
  };

  const renderErrorMessages = ({ messages }: { messages?: MultipleFieldErrors }) => {
    return messages
        ? Object.entries(messages).map(([type, message]) => (
            <small className="help-block" key={type}>{String(message)}</small>
        ))
        : null;
  };

  return (
    <>
      <Meta title={intl.formatMessage({ id: "kontakt.title" })} description={intl.formatMessage({ id: "kontakt.description" })} />
      <section className="padding">
        <Images />
        <SectionContent heading={intl.formatMessage({ id: "kontakt.title" })} />
        <Arrow url={"#"+replaceSpecialChars(intl.formatMessage({ id: "kontakt.title" }))} text={intl.formatMessage({ id: "kontakt.title" })} />
      </section>
      <section id={replaceSpecialChars(intl.formatMessage({ id: "kontakt.title" }))} className="padding">
        <div className="container">
          <div className="section-content right padding" data-title={intl.formatMessage({ id: "data.dulezite" })}>
            <h2><FormattedMessage id="kontakt.title" /></h2>
            <ul>
              <li>
                <address>
                  Veronika Zelenková<br />
                  Studio Pano<br />
                  Masarykova tř. 2475/57<br />
                  415 01 <strong>Teplice</strong>
                </address>
              </li>
              <li>
                <FormattedMessage id="kontakt.ic" /> 86742701<br />
                <FormattedMessage id="kontakt.fyzicka" />
              </li>
              <li><a href="https://www.google.com/maps/place/Permanentn%C3%AD+make-up+Veronika/@50.6475556,13.8329472,18z/data=!4m5!3m4!1s0x47098fcf9e4cf0af:0xa2efe6bff2d17392!8m2!3d50.6474931!4d13.8341861" title={intl.formatMessage({ id: "kontakt.otevrit" })} target="_blank" rel="noreferrer"><FormattedMessage id="kontakt.otevrit" /></a></li>
              <li>
                <FormattedMessage id="kontakt.telefon" />: <a href="tel:+420728313090" title="+420 728 313 090">+420 728 313 090</a><br />
                <FormattedMessage id="kontakt.objednani" />
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section id={replaceSpecialChars(intl.formatMessage({ id: "data.zeptejtese" }))} className="form-section padding">
        <div className="container">
          <div className="section-content left padding" data-title={intl.formatMessage({ id: "data.zeptejtese" })}>
            <h2 className="sr-only"><FormattedMessage id="data.zeptejtese" /></h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label htmlFor="name"><FormattedMessage id="kontakt.jmeno" /></label>
                <input className="form-control" type="text" id="name" placeholder={intl.formatMessage({ id: "placeholder.jmeno" })}
                  {...register("name", {
                    required: intl.formatMessage({ id: "chyba.jmeno" })
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={renderErrorMessages}
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone"><FormattedMessage id="kontakt.telefon" /></label>
                <input className="form-control" type="tel" id="phone" placeholder={intl.formatMessage({ id: "placeholder.telefon" })}
                  {...register("phone", {
                    required: intl.formatMessage({ id: "chyba.telefon" }),
                    pattern: {
                      value: /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/,
                      message: intl.formatMessage({ id: "chyba.pattern" })
                    }
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="phone"
                  render={renderErrorMessages}
                />
              </div>
              <div className="form-group">
                <label htmlFor="message"><FormattedMessage id="kontakt.dotaz" /></label>
                <textarea className="form-control" id="message" placeholder={intl.formatMessage({ id: "placeholder.dotaz" })} rows={5}
                  {...register("message", {
                    required: intl.formatMessage({ id: "chyba.dotaz" })
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="message"
                  render={renderErrorMessages}
                />
              </div>
              <div className="form-group">
                <button className="button" type="submit" aria-label={intl.formatMessage({ id: "kontakt.odeslat" })}><FormattedMessage id="kontakt.odeslat" /></button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};
