import { FormattedMessage, useIntl } from "react-intl";
import { ReactCompareSlider, ReactCompareSliderImage } from "react-compare-slider";

export const Compare: React.FC<{lower: string, count: number, left?: boolean}> = ({ lower, count, left = true }) => {
	const images = [];

  const intl = useIntl();

  for (let i = 1; i <= count; i++) {
    images.push(
      {
        before: require(`../../../themes/frontend/img/gallery/${lower}/compare/${i}_before.webp`).default,
        after: require(`../../../themes/frontend/img/gallery/${lower}/compare/${i}_after.webp`).default,
      }
    );
  }

  return (
    <section id="gallery" className="padding">
      <div className="container">
        <h2 className="sr-only"><FormattedMessage id="data.predapo" /></h2>
        <div className="gallery-compare">
          <div className={`section-content ${ left ? "left" : "right" }`} data-title={intl.formatMessage({ id: "data.predapo" })}>
            {images.map((_im, idx) =>
              <span className="gallery-image" key={idx}>
                <ReactCompareSlider
                  itemOne={<ReactCompareSliderImage src={_im.before} alt="Před" />}
                  itemTwo={<ReactCompareSliderImage src={_im.after} alt="Po" />}
                />
              </span>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
