import { Meta, Images, SectionContent, Arrow, Gallery, Video, replaceSpecialChars, Accordion } from "modules/layout";
import { FormattedMessage, useIntl } from "react-intl";

export const OcniLinky: React.FC = () => {
  const intl = useIntl();

  return (
    <>
      <Meta title={intl.formatMessage({ id: "ocnilinky.title" })} description={intl.formatMessage({ id: "ocnilinky.description" })} /> 
      <section className="padding">
        <Images />
        <SectionContent heading={intl.formatMessage({ id: "ocnilinky.title" })} />
        <Arrow url={"#"+replaceSpecialChars(intl.formatMessage({ id: "ocnilinky.title" }))} text={intl.formatMessage({ id: "ocnilinky.title" })} />
      </section>
      <section id={replaceSpecialChars(intl.formatMessage({ id: "ocnilinky.title" }))} className="padding">
        <div className="container">
          <div className="section-content left padding" data-title={intl.formatMessage({ id: "data.dulezite" })}>
            <h2><FormattedMessage id="ocnilinky.title" /></h2>
            <ul>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="ocnilinky.co" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="ocnilinky.co1" /></li>
                  <li><FormattedMessage id="ocnilinky.co2" /></li>
                  <li><FormattedMessage id="ocnilinky.co3" /></li>
                  <li><FormattedMessage id="ocnilinky.co4" /></li>
                  <li><FormattedMessage id="ocnilinky.co5" /></li>
                </ul>
              </li>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="ocnilinky.jak" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="ocnilinky.jak1" /></li>
                  <li><FormattedMessage id="ocnilinky.jak2" /></li>
                  <li><FormattedMessage id="ocnilinky.jak3" /></li>
                  <li><FormattedMessage id="ocnilinky.jak4" /></li>
                </ul>
              </li>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="ocnilinky.vedet" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="ocnilinky.vedet1" /></li>
                  <li><FormattedMessage id="ocnilinky.vedet2" /></li>
                  <li><FormattedMessage id="ocnilinky.vedet3" /></li>
                  <li><FormattedMessage id="ocnilinky.vedet4" /></li>
                  <li><FormattedMessage id="ocnilinky.vedet5" /></li>
                  <li><FormattedMessage id="ocnilinky.vedet6" /></li>
                  <li><FormattedMessage id="ocnilinky.vedet7" /></li>
                  <li><FormattedMessage id="ocnilinky.vedet8" /></li>
                  <li><FormattedMessage id="ocnilinky.vedet9" /></li>
                </ul>
              </li>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="ocnilinky.pro" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="ocnilinky.kontraindikace" />
                    <ul>
                      <li><FormattedMessage id="ocnilinky.kontraindikace1" /></li>
                      <li><FormattedMessage id="ocnilinky.kontraindikace2" /></li>
                      <li><FormattedMessage id="ocnilinky.kontraindikace3" /></li>
                      <li><FormattedMessage id="ocnilinky.kontraindikace4" /></li>
                      <li><FormattedMessage id="ocnilinky.kontraindikace5" /></li>
                      <li><FormattedMessage id="ocnilinky.kontraindikace6" /></li>
                      <li><FormattedMessage id="ocnilinky.kontraindikace7" /></li>
                      <li><FormattedMessage id="ocnilinky.kontraindikace8" /></li>
                      <li><FormattedMessage id="ocnilinky.kontraindikace9" /></li>
                      <li><FormattedMessage id="ocnilinky.kontraindikace10" /></li>
                      <li><FormattedMessage id="ocnilinky.kontraindikace11" /></li>
                      <li><FormattedMessage id="ocnilinky.kontraindikace12" /></li>
                      <li><FormattedMessage id="ocnilinky.kontraindikace13" /></li>
                      <li><FormattedMessage id="ocnilinky.kontraindikace14" /></li>
                      <li><FormattedMessage id="ocnilinky.kontraindikace15" /></li>
                      <li><FormattedMessage id="ocnilinky.kontraindikace16" /></li>
                      <li><FormattedMessage id="ocnilinky.kontraindikace17" /></li>
                      <li><FormattedMessage id="ocnilinky.kontraindikace18" /></li>
                      <li><FormattedMessage id="ocnilinky.kontraindikace19" /></li>
                      <li><FormattedMessage id="ocnilinky.kontraindikace20" /></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="ocnilinky.boli" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="ocnilinky.boli1" /></li>
                  <li><FormattedMessage id="ocnilinky.boli2" /></li>
                </ul>
              </li>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="ocnilinky.pecovat" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="ocnilinky.pecovat1" /></li>
                  <li><FormattedMessage id="ocnilinky.pecovat2" /></li>
                  <li><FormattedMessage id="ocnilinky.pecovat3" /></li>
                </ul>
              </li>
              <li className="accordion has-pricelist">
                <span onClick={Accordion}><FormattedMessage id="ocnilinky.kolik" /></span>
                <ul className="panel">
                  <li className="pricelist">
                    <ul className="pricelist-inner">
                      <li className="pricelist-row">
                        <span><FormattedMessage id="ocnilinky.natural" /></span>
                        <span><strong><FormattedMessage id="ocnilinky.cena1" /></strong></span>
                      </li>
                    </ul>
                  </li>
                  <li><FormattedMessage id="data.zahrnuje" />
                    <ul>
                      <li><FormattedMessage id="ocnilinky.zahrnuje1" /></li>
                      <li><FormattedMessage id="ocnilinky.zahrnuje2" /></li>
                      <li><FormattedMessage id="ocnilinky.zahrnuje3" /></li>
                      <li><FormattedMessage id="ocnilinky.zahrnuje4" /></li>
                      <li><FormattedMessage id="ocnilinky.zahrnuje5" /></li>
                      <li><FormattedMessage id="ocnilinky.zahrnuje6" /></li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <br /><br />
            <h3><FormattedMessage id="data.neprehlednete" /></h3>
            <ul>
              <li><FormattedMessage id="ocnilinky.neprehlednete1" /></li>
              <li><FormattedMessage id="data.neprehlednete2" /></li>
              <li><FormattedMessage id="data.neprehlednete3" /></li>
              <li><FormattedMessage id="data.neprehlednete4" /></li>
              <li><FormattedMessage id="data.neprehlednete5" /></li>
            </ul>
          </div>
        </div>
      </section>
      <Gallery lower="ocni-linky" text={intl.formatMessage({ id: "ocnilinky.title" })} count={6} left={false} />
      <Video lower="ocni-linky" text={intl.formatMessage({ id: "ocnilinky.title" })} count={1} />
    </>
  );
};
