import { Image } from "modules/layout";
import { useIntl } from "react-intl";

export const Images: React.FC = () => {
  const runCallback = (cb:any) => {
    return cb();
  };

  const intl = useIntl();

  return (
    <div className="images-background">
      {
        runCallback(() => {
          const row = [];
          for (let i = 1; i < 9; i++) {
            row.push(
              <div className="image" key={i}>
                <Image src={`images/${i}-640x640`} alt={intl.formatMessage({ id: "home.permanent" }) + " Veronika"} width={640} height={640} lazy={false} />
              </div>
            );
          }
          return row;
        })
      }
    </div>
  );
};
