import { Meta, Images, SectionContent, Arrow, Gallery, replaceSpecialChars, Accordion } from "modules/layout";
import { FormattedMessage, useIntl } from "react-intl";

export const MiniAquarelleTattoo: React.FC = () => {
  const intl = useIntl();

  return (
    <>
      <Meta title={intl.formatMessage({ id: "miniaquarelletattoo.title" })} description={intl.formatMessage({ id: "miniaquarelletattoo.description" })} /> 
      <section className="padding">
        <Images />
        <SectionContent heading={intl.formatMessage({ id: "miniaquarelletattoo.title" })} />
        <Arrow url={"#"+replaceSpecialChars(intl.formatMessage({ id: "miniaquarelletattoo.title" }))} text={intl.formatMessage({ id: "miniaquarelletattoo.title" })} />
      </section>
      <section id={replaceSpecialChars(intl.formatMessage({ id: "miniaquarelletattoo.title" }))} className="padding">
        <div className="container">
          <div className="section-content right padding" data-title={intl.formatMessage({ id: "data.dulezite" })}>
            <h2><FormattedMessage id="miniaquarelletattoo.title" /></h2>
            <ul>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="miniaquarelletattoo.co" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="miniaquarelletattoo.co1" /></li>
                  <li><FormattedMessage id="miniaquarelletattoo.co2" /></li>
                  <li><FormattedMessage id="miniaquarelletattoo.co3" /></li>
                  <li><FormattedMessage id="miniaquarelletattoo.co4" /></li>
                </ul>
              </li>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="miniaquarelletattoo.pro" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="miniaquarelletattoo.kontraindikace" />
                    <ul>
                      <li><FormattedMessage id="miniaquarelletattoo.kontraindikace1" /></li>
                      <li><FormattedMessage id="miniaquarelletattoo.kontraindikace2" /></li>
                      <li><FormattedMessage id="miniaquarelletattoo.kontraindikace3" /></li>
                      <li><FormattedMessage id="miniaquarelletattoo.kontraindikace4" /></li>
                      <li><FormattedMessage id="miniaquarelletattoo.kontraindikace5" /></li>
                      <li><FormattedMessage id="miniaquarelletattoo.kontraindikace6" /></li>
                      <li><FormattedMessage id="miniaquarelletattoo.kontraindikace7" /></li>
                      <li><FormattedMessage id="miniaquarelletattoo.kontraindikace8" /></li>
                      <li><FormattedMessage id="miniaquarelletattoo.kontraindikace9" /></li>
                      <li><FormattedMessage id="miniaquarelletattoo.kontraindikace10" /></li>
                      <li><FormattedMessage id="miniaquarelletattoo.kontraindikace11" /></li>
                      <li><FormattedMessage id="miniaquarelletattoo.kontraindikace12" /></li>
                      <li><FormattedMessage id="miniaquarelletattoo.kontraindikace13" /></li>
                      <li><FormattedMessage id="miniaquarelletattoo.kontraindikace14" /></li>
                      <li><FormattedMessage id="miniaquarelletattoo.kontraindikace15" /></li>
                      <li><FormattedMessage id="miniaquarelletattoo.kontraindikace16" /></li>
                      <li><FormattedMessage id="miniaquarelletattoo.kontraindikace17" /></li>
                      <li><FormattedMessage id="miniaquarelletattoo.kontraindikace18" /></li>
                      <li><FormattedMessage id="miniaquarelletattoo.kontraindikace19" /></li>
                      <li><FormattedMessage id="miniaquarelletattoo.kontraindikace20" /></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="miniaquarelletattoo.pecovat" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="miniaquarelletattoo.pecovat1" /></li>
                  <li><FormattedMessage id="miniaquarelletattoo.pecovat2" /></li>
                </ul>
              </li>
              <li className="accordion has-pricelist">
                <span onClick={Accordion}><FormattedMessage id="miniaquarelletattoo.kolik" /></span>
                <ul className="panel">
                  <li className="pricelist">
                    <ul className="pricelist-inner">
                      <li><FormattedMessage id="miniaquarelletattoo.cena" /></li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <br /><br />
            <h3><FormattedMessage id="data.neprehlednete" /></h3>
            <ul>
              <li><FormattedMessage id="data.neprehlednete5" /></li>
            </ul>
          </div>
        </div>
      </section>  
      <Gallery lower="mini-aquarelle-tattoo" text={intl.formatMessage({ id: "miniaquarelletattoo.title" })} count={11} />    
    </>
  );
};
