import { useState } from "react";
import ContentLoader from "react-content-loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

export const Image: React.FC<{clas?: string, src: string, alt: string, width: number, height: number, lazy?: boolean}> = ({ clas, src, alt, width, height, lazy = true }) => {
  const [loaded, setLoaded] = useState(false);

  const handleLoad = () => setLoaded(true);

  return (
    <>
      {lazy ? (
        <>
          {loaded ? null : (
            <ContentLoader viewBox={"0 0 "+(width)+" "+(height)+""}>
              <rect x="0" y="0" width={width} height={height} />
            </ContentLoader>
          )}
          <LazyLoadImage
            className={clas}
            style={loaded ? {} : { display: "none" }}
            src={require(`../../../themes/frontend/img/${src}.webp`).default}
            alt={alt}
            width={width}
            height={height}
            effect="opacity"
            afterLoad={handleLoad}
          /> 
        </>
      ) : (
          <LazyLoadImage
            className={clas}
            src={require(`../../../themes/frontend/img/${src}.webp`).default}
            alt={alt}
            width={width}
            height={height}
            effect="opacity"
          /> 
      )}
    </>
  );
};
