import { isMobile } from "react-device-detect";
import Cookies from "js-cookie";
import { BrowserRouter, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async"; 
import { AppRoute, AppLanguage } from "const";
import * as views from "views";
import { AppLayout } from "modules/layout";
import { LocalizedRouter, LocalizedSwitch, appStrings } from "modules/i18n";

export const App: React.FC = () => {
  const myResizeFunction = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", vh + "px");

    const scroller = document.documentElement;
    requestAnimationFrame(()=>{
      scroller.style
        .setProperty(
          "--vw", 
          scroller.clientWidth + "px"
        );
      scroller.style
        .setProperty(
          "--scrollbar-width", 
          window.innerWidth - scroller.clientWidth + "px"
      );    
    });
  };

  if (isMobile) {
    myResizeFunction();
  } else {
    window.addEventListener("resize", () => {
      myResizeFunction();
    });
  }

  if (isMobile) {
    document.getElementsByTagName("html")[0].classList.add("touch");
  } else {
    document.getElementsByTagName("html")[0].classList.remove("touch");
  }

  window.onscroll = () => {
    if (window.pageYOffset >= document.getElementsByTagName("header")[0].offsetHeight) {
      document.getElementsByTagName("header")[0].classList.add("scrolled");
    } else {
      document.getElementsByTagName("header")[0].classList.remove("scrolled");
    }
  };

  const cookieValue = Cookies.get("formSubmitted");
  if (cookieValue === "1") {
    Cookies.set("formSubmitted", "0");
  }

  return (
    <HelmetProvider>  
      <LocalizedRouter
        RouterComponent={BrowserRouter}
        languages={AppLanguage}
        appStrings={appStrings}
      >
        <AppLayout>
          <LocalizedSwitch>
            <Route exact path={AppRoute.Home}>
              <views.Home />
            </Route>
            <Route exact path={AppRoute.Omne}>
              <views.Omne />
            </Route>
            <Route exact path={AppRoute.PudroveOboci}>
              <views.PudroveOboci />
            </Route>
            <Route exact path={AppRoute.Rty}>
              <views.Rty />
            </Route>
            <Route exact path={AppRoute.OcniLinky}>
              <views.OcniLinky />
            </Route>
            <Route exact path={AppRoute.MiniAquarelleTattoo}>
              <views.MiniAquarelleTattoo />
            </Route>
            <Route exact path={AppRoute.OdstraneniPMU}>
              <views.OdstraneniPMU />
            </Route>
            <Route exact path={AppRoute.NastrelovaniNausnic}>
              <views.NastrelovaniNausnic />
            </Route>
            <Route exact path={AppRoute.Cenik}>
              <views.Cenik />
            </Route>
            <Route exact path={AppRoute.DarkovePoukazy}>
              <views.DarkovePoukazy />
            </Route>
            <Route exact path={AppRoute.Kontakt}>
              <views.Kontakt />
            </Route>
            <Route exact path={AppRoute.FormularOdeslan}>
              <views.FormularOdeslan />
            </Route>
            <Route path="*">
              <views.GeneralError />
            </Route>
          </LocalizedSwitch>
        </AppLayout>
      </LocalizedRouter>
    </HelmetProvider>  
  );
};
