import Cookies from "js-cookie";
import { Meta, Images, SectionContent, Arrow, replaceSpecialChars } from "modules/layout";
import { FormattedMessage, useIntl } from "react-intl";
import { Redirect, Link } from "react-router-dom";
import { AppRoute } from "const";

export const FormularOdeslan: React.FC = () => {
  const { formatMessage, locale } = useIntl();
  const intl = useIntl();
  
  const cookieValue = Cookies.get("formSubmitted");
  if (cookieValue !== "1") {
    return <Redirect to={localizeRouteKey(AppRoute.Home)} />;
  }

  function localizeRouteKey(path: string) {
    return `/${locale}` + formatMessage({ id: path });
  }

  return (
    <>
      <Meta title={intl.formatMessage({ id: "formularodeslan.title" })} description={intl.formatMessage({ id: "home.description" })} /> 
      <section className="padding">
        <Images />
        <SectionContent heading={intl.formatMessage({ id: "formularodeslan.title" })} />
        <Arrow url={"#"+replaceSpecialChars(intl.formatMessage({ id: "formularodeslan.title" }))} text={intl.formatMessage({ id: "formularodeslan.title" })} />
      </section>
      <section id={replaceSpecialChars(intl.formatMessage({ id: "formularodeslan.title" }))} className="padding">
        <div className="container">
          <div className="section-content left padding" data-title={intl.formatMessage({ id: "data.dulezite" })}>
            <h2><FormattedMessage id="formularodeslan.text1" /></h2>
            <p><FormattedMessage id="formularodeslan.text2" /></p>
            <p><FormattedMessage id="data.vrattese" /><Link className="black" to={localizeRouteKey(AppRoute.Home)} title={intl.formatMessage({ id: "data.uvodnistranka" })}><FormattedMessage id="data.uvodnistranku" /></Link>.</p>
          </div>
        </div>
      </section>  
    </>
  );
};
