import { IntlProvider } from "react-intl";
import { Route, Redirect } from "react-router-dom";
import { AppLanguage } from "const";
import { LanguageStrings } from "../localizations";

interface Props {
  RouterComponent: React.ComponentClass<any>;
  languages: { [k: number]: string };
  appStrings: { [prop: string]: LanguageStrings };
  defaultLanguage?: AppLanguage;
}

export const LocalizedRouter: React.FC<Props> = ({
  children,
  RouterComponent,
  appStrings,
  defaultLanguage
}) => (
  <RouterComponent>
    <Route path="/:lang([a-zA-Z]{2})?">
      {({ match, location }) => {
        const params = match ? match.params : {};
        const lang = params.lang || defaultLanguage || AppLanguage.Czech;
        const { pathname } = location;

        if (lang === AppLanguage.Czech && pathname.startsWith("/cs")) {
          return <Redirect to={pathname.replace(/^\/cs/, "")} />;
        }

        if (lang !== AppLanguage.Czech && !pathname.startsWith(`/${lang}/`)) {
          return <Redirect to={`/${lang}${pathname}`} />;
        }

        return (
          <IntlProvider locale={lang} messages={appStrings[lang]}>
            {children}
          </IntlProvider>
        );
      }}
    </Route>
  </RouterComponent>
);
