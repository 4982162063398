import { Meta, Images, SectionContent, Arrow, Gallery, Video, replaceSpecialChars } from "modules/layout";
import { FormattedMessage, useIntl } from "react-intl";

export const Omne: React.FC = () => {
  const intl = useIntl();

  return (
    <>
      <Meta title={intl.formatMessage({ id: "omne.title" })} description={intl.formatMessage({ id: "omne.description" })} />
      <section className="padding">
        <Images />
        <SectionContent heading={intl.formatMessage({ id: "omne.title" })} />
        <Arrow url={"#"+replaceSpecialChars(intl.formatMessage({ id: "omne.title" }))} text={intl.formatMessage({ id: "omne.title" })} />
      </section>
      <section id={replaceSpecialChars(intl.formatMessage({ id: "omne.title" }))} className="padding">
        <div className="container">
          <div className="section-content left padding" data-title={intl.formatMessage({ id: "data.dulezite" })}>
            <h2><FormattedMessage id="omne.title" /></h2>
            <ul>
              <li><FormattedMessage id="omne.text1" /></li>
              <li><FormattedMessage id="omne.text2" /></li>
              <li><FormattedMessage id="omne.text3" /></li>
              <li><FormattedMessage id="omne.text4" /></li>
              <li><FormattedMessage id="omne.text5" /></li>
              <li><FormattedMessage id="omne.text6" /></li>
              <li><FormattedMessage id="omne.text7" /></li>
              <li><FormattedMessage id="omne.text8" /></li>
              <li><FormattedMessage id="omne.text9" /></li>
              <li><FormattedMessage id="omne.text10" /></li>
              <li><FormattedMessage id="omne.text11" /></li>
              <li><FormattedMessage id="omne.text12" /></li>
              <li><FormattedMessage id="omne.text13" /></li>
              <li><FormattedMessage id="omne.text14" /></li>
              <li><FormattedMessage id="omne.text15" /></li>
              <li><FormattedMessage id="omne.text16" /></li>
              <li><FormattedMessage id="omne.text17" /></li>
              <li><FormattedMessage id="omne.text18" /></li>
              <li><FormattedMessage id="omne.text19" /></li>
              <li><FormattedMessage id="omne.text20" /></li>
              <li><FormattedMessage id="omne.text21" /></li>
              <li><FormattedMessage id="omne.text22" /></li>
              <li><FormattedMessage id="omne.text23" /></li>
            </ul>
          </div>
        </div>
      </section>
      <Gallery lower="o-mne" text={intl.formatMessage({ id: "omne.title" })} count={32} left={false} />
      <Video lower="o-mne" text={intl.formatMessage({ id: "omne.title" })} count={9} />
    </>
  );
};
