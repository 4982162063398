import { Meta, Images, SectionContent, Arrow, Compare, replaceSpecialChars, Accordion } from "modules/layout";
import { FormattedMessage, useIntl } from "react-intl";

export const OdstraneniPMU: React.FC = () => {
  const intl = useIntl();

  return (
    <>
      <Meta title={intl.formatMessage({ id: "odstranenipmu.title" })} description={intl.formatMessage({ id: "odstranenipmu.description" })} /> 
      <section className="padding">
        <Images />
        <SectionContent heading={intl.formatMessage({ id: "odstranenipmu.title" })} />
        <Arrow url={"#"+replaceSpecialChars(intl.formatMessage({ id: "odstranenipmu.title" }))} text={intl.formatMessage({ id: "odstranenipmu.title" })} />
      </section>
      <section id={replaceSpecialChars(intl.formatMessage({ id: "odstranenipmu.title" }))} className="padding">
        <div className="container">
          <div className="section-content right padding" data-title={intl.formatMessage({ id: "data.dulezite" })}>
            <h2><FormattedMessage id="odstranenipmu.title" /></h2>
            <ul>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="odstranenipmu.co" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="odstranenipmu.co1" /></li>
                </ul>
              </li>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="odstranenipmu.jak" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="odstranenipmu.jak1" /></li>
                  <li><FormattedMessage id="odstranenipmu.jak2" /></li>
                  <li><FormattedMessage id="odstranenipmu.jak3" /></li>
                </ul>
              </li>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="odstranenipmu.vedet" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="odstranenipmu.vedet1" /></li>
                  <li><FormattedMessage id="odstranenipmu.vedet2" /></li>
                  <li><FormattedMessage id="odstranenipmu.vedet3" /></li>
                  <li><FormattedMessage id="odstranenipmu.vedet4" /></li>
                  <li><FormattedMessage id="odstranenipmu.vedet5" /></li>
                  <li><FormattedMessage id="odstranenipmu.vedet6" /></li>
                  <li><FormattedMessage id="odstranenipmu.vedet7" /></li>
                </ul>
              </li>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="odstranenipmu.pro" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="odstranenipmu.kontraindikace" />
                    <ul>
                      <li><FormattedMessage id="odstranenipmu.kontraindikace1" /></li>
                      <li><FormattedMessage id="odstranenipmu.kontraindikace2" /></li>
                      <li><FormattedMessage id="odstranenipmu.kontraindikace3" /></li>
                      <li><FormattedMessage id="odstranenipmu.kontraindikace4" /></li>
                      <li><FormattedMessage id="odstranenipmu.kontraindikace5" /></li>
                      <li><FormattedMessage id="odstranenipmu.kontraindikace6" /></li>
                      <li><FormattedMessage id="odstranenipmu.kontraindikace7" /></li>
                      <li><FormattedMessage id="odstranenipmu.kontraindikace8" /></li>
                      <li><FormattedMessage id="odstranenipmu.kontraindikace9" /></li>
                      <li><FormattedMessage id="odstranenipmu.kontraindikace10" /></li>
                      <li><FormattedMessage id="odstranenipmu.kontraindikace11" /></li>
                      <li><FormattedMessage id="odstranenipmu.kontraindikace12" /></li>
                      <li><FormattedMessage id="odstranenipmu.kontraindikace13" /></li>
                      <li><FormattedMessage id="odstranenipmu.kontraindikace14" /></li>
                      <li><FormattedMessage id="odstranenipmu.kontraindikace15" /></li>
                      <li><FormattedMessage id="odstranenipmu.kontraindikace16" /></li>
                      <li><FormattedMessage id="odstranenipmu.kontraindikace17" /></li>
                      <li><FormattedMessage id="odstranenipmu.kontraindikace18" /></li>
                      <li><FormattedMessage id="odstranenipmu.kontraindikace19" /></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="odstranenipmu.boli" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="odstranenipmu.boli1" /></li>
                  <li><FormattedMessage id="odstranenipmu.boli2" /></li>
                  <li><FormattedMessage id="odstranenipmu.boli3" /></li>
                </ul>
              </li>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="odstranenipmu.pecovat" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="odstranenipmu.pecovat1" /></li>
                  <li><FormattedMessage id="odstranenipmu.pecovat2" /></li>
                </ul>
              </li>
              <li className="accordion has-pricelist">
                <span onClick={Accordion}><FormattedMessage id="odstranenipmu.kolik" /></span>
                <ul className="panel">
                  <li className="pricelist">
                    <ul className="pricelist-inner">
                      <li className="pricelist-row">
                        <span><FormattedMessage id="odstranenipmu.sezeni" /></span>
                        <span><strong><FormattedMessage id="odstranenipmu.cena1" /></strong></span>
                      </li>
                      <li><FormattedMessage id="odstranenipmu.cena2" /></li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <br /><br />
            <h3><FormattedMessage id="data.neprehlednete" /></h3>
            <ul>
              <li><FormattedMessage id="odstranenipmu.neprehlednete" /></li>
              <li><FormattedMessage id="data.neprehlednete5" /></li>
            </ul>
          </div>
        </div>
      </section>  
      <Compare lower="odstraneni-pmu" count={12} />
    </>
  );
};
