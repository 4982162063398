import { Meta, Images, SectionContent, Arrow, replaceSpecialChars } from "modules/layout";
import { FormattedMessage, useIntl } from "react-intl";

export const DarkovePoukazy: React.FC = () => {
  const intl = useIntl();

  return (
    <>
      <Meta title={intl.formatMessage({ id: "darkovepoukazy.title" })} description={intl.formatMessage({ id: "darkovepoukazy.description" })} /> 
      <section className="padding">
        <Images />
        <SectionContent heading={intl.formatMessage({ id: "darkovepoukazy.title" })} />
        <Arrow url={"#"+replaceSpecialChars(intl.formatMessage({ id: "darkovepoukazy.title" }))} text={intl.formatMessage({ id: "darkovepoukazy.title" })} />
      </section>
      <section id={replaceSpecialChars(intl.formatMessage({ id: "darkovepoukazy.title" }))} className="padding">
        <div className="container">
          <div className="section-content left padding" data-title={intl.formatMessage({ id: "data.dulezite" })}>
            <h2><FormattedMessage id="darkovepoukazy.title" /></h2>
            <ul>
              <li>
                <FormattedMessage
                  id="darkovepoukazy.text1"
                  values={{
                    strong: (chunks: any) => <strong>{chunks}</strong>
                  }}
                />
              </li>
              <li><FormattedMessage id="darkovepoukazy.text2" /></li>
            </ul>
            <br /><br />
            <h3><FormattedMessage id="darkovepoukazy.text3" /></h3>
            <ul>
              <li><FormattedMessage id="darkovepoukazy.text4" /></li>
              <li><FormattedMessage id="darkovepoukazy.text5" /></li>
              <li>
                <FormattedMessage
                  id="darkovepoukazy.text6"
                  values={{
                    strong: (chunks: any) => <strong>{chunks}</strong>
                  }}
                />
              </li>
              <li><FormattedMessage id="darkovepoukazy.text7" /></li>
              <li><FormattedMessage id="darkovepoukazy.text8" /></li>
              <li><FormattedMessage id="darkovepoukazy.text9" /></li>
              <li><FormattedMessage id="darkovepoukazy.text10" /></li>
            </ul>
            <br /><br />
            <h3><FormattedMessage id="darkovepoukazy.text11" /></h3>
            <ul>
              <li><FormattedMessage id="darkovepoukazy.text12" /></li>
              <li><FormattedMessage id="darkovepoukazy.text13" /></li>
            </ul>
          </div>
        </div>
      </section>   
    </>
  );
};
