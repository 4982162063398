import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";

export const Meta: React.FC<{clas?:string, title:string, description:string}> = ({ clas, title, description }) => {
  const intl = useIntl();

  useEffect(() => {
    function fetchBusinesses() {
      window.scrollTo(0, 0);
  
      document.querySelectorAll("meta[name*='title'], meta[property*='title']").forEach(anchor => {
        anchor.setAttribute("content", `${title} | Permanentní make-up Veronika`);
      });
      document.querySelectorAll("meta[name*='description'], meta[property*='description']").forEach(anchor => {
        anchor.setAttribute("content", `${description}`);
      });
      document.querySelectorAll("meta[property='og:url']").forEach(anchor => {
        anchor.setAttribute("content", window.location.href);
      });
      document.querySelectorAll("link[rel='canonical']").forEach(anchor => {
        anchor.setAttribute("href", window.location.href);
      });
    }
    fetchBusinesses();
  }, [title, description]);

  return (     
    <Helmet>  
      <html lang={intl.formatMessage({ id: "data.lang" })} />  
      <title>{title} | Permanentní make-up Veronika</title>
      <body className={clas} />  
    </Helmet> 
  );
};
