import { Meta, Images, SectionContent, Arrow, Gallery, Video, replaceSpecialChars, Accordion } from "modules/layout";
import { FormattedMessage, useIntl } from "react-intl";

export const Rty: React.FC = () => {
  const intl = useIntl();

  return (
    <>
      <Meta title={intl.formatMessage({ id: "rty.title" })} description={intl.formatMessage({ id: "rty.description" })} /> 
      <section className="padding">
        <Images />
        <SectionContent heading={intl.formatMessage({ id: "rty.title" })} />
        <Arrow url={"#"+replaceSpecialChars(intl.formatMessage({ id: "rty.title" }))} text={intl.formatMessage({ id: "rty.title" })} />
      </section>
      <section id={replaceSpecialChars(intl.formatMessage({ id: "rty.title" }))} className="padding">
        <div className="container">
          <div className="section-content left padding" data-title={intl.formatMessage({ id: "data.dulezite" })}>
            <h2><FormattedMessage id="rty.title" /></h2>
            <ul>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="rty.co" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="rty.co1" /></li>
                  <li><FormattedMessage id="rty.co2" /></li>
                  <li><FormattedMessage id="rty.co3" /></li>
                </ul>
              </li>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="rty.styly" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="rty.styly1" /></li>
                  <li><FormattedMessage id="rty.styly2" />
                    <ul>
                      <li><strong>Aquarelle</strong>
                        <ul>
                          <li><FormattedMessage id="rty.aquarelle1" /><br /><FormattedMessage id="rty.aquarelle2" /><br /><FormattedMessage id="rty.aquarelle3" /></li>
                        </ul>
                      </li>
                      <li />
                      <li><strong>Fullshading</strong>
                        <ul>
                          <li><FormattedMessage id="rty.fullshading" /></li>
                        </ul>
                      </li>
                      <li />
                      <li><strong>Nude Lips</strong>
                        <ul>
                          <li><FormattedMessage id="rty.nudelips1" /><br /><FormattedMessage id="rty.nudelips2" /><br /><FormattedMessage id="rty.nudelips3" /></li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="rty.jak" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="rty.jak1" /></li>
                  <li><FormattedMessage id="rty.jak2" /></li>
                  <li><FormattedMessage id="rty.jak3" /></li>
                  <li><FormattedMessage id="rty.jak4" /></li>
                  <li><FormattedMessage id="rty.jak5" /></li>
                  <li><FormattedMessage id="rty.jak6" /></li>
                </ul>
              </li>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="rty.vedet" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="rty.vedet1" /></li>
                  <li><FormattedMessage id="rty.vedet2" /></li>
                  <li><FormattedMessage id="rty.vedet3" /></li>
                  <li><FormattedMessage id="rty.vedet4" /></li>
                  <li><FormattedMessage id="rty.vedet5" /></li>
                  <li><FormattedMessage id="rty.vedet6" /></li>
                </ul>
              </li>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="rty.pro" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="rty.pro1" /></li>
                  <li><FormattedMessage id="rty.kontraindikace" />
                    <ul>
                      <li><FormattedMessage id="rty.kontraindikace1" /></li>
                      <li><FormattedMessage id="rty.kontraindikace2" /></li>
                      <li><FormattedMessage id="rty.kontraindikace3" /></li>
                      <li><FormattedMessage id="rty.kontraindikace4" /></li>
                      <li><FormattedMessage id="rty.kontraindikace5" /></li>
                      <li><FormattedMessage id="rty.kontraindikace6" /></li>
                      <li><FormattedMessage id="rty.kontraindikace7" /></li>
                      <li><FormattedMessage id="rty.kontraindikace8" /></li>
                      <li><FormattedMessage id="rty.kontraindikace9" /></li>
                      <li><FormattedMessage id="rty.kontraindikace10" /></li>
                      <li><FormattedMessage id="rty.kontraindikace11" /></li>
                      <li><FormattedMessage id="rty.kontraindikace12" /></li>
                      <li><FormattedMessage id="rty.kontraindikace13" /></li>
                      <li><FormattedMessage id="rty.kontraindikace14" /></li>
                      <li><FormattedMessage id="rty.kontraindikace15" /></li>
                      <li><FormattedMessage id="rty.kontraindikace16" /></li>
                      <li><FormattedMessage id="rty.kontraindikace17" /></li>
                      <li><FormattedMessage id="rty.kontraindikace18" /></li>
                      <li><FormattedMessage id="rty.kontraindikace19" /></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="rty.boli" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="rty.boli1" /></li>
                  <li><FormattedMessage id="rty.boli2" /></li>
                </ul>
              </li>
              <li className="accordion">
                <span onClick={Accordion}><FormattedMessage id="rty.pecovat" /></span>
                <ul className="panel">
                  <li><FormattedMessage id="rty.pecovat1" /></li>
                  <li><FormattedMessage id="rty.pecovat2" /></li>
                  <li><FormattedMessage id="rty.pecovat3" /></li>
                </ul>
              </li>
              <li className="accordion has-pricelist">
                <span onClick={Accordion}><FormattedMessage id="rty.kolik" /></span>
                <ul className="panel">
                  <li className="pricelist">
                    <ul className="pricelist-inner">
                      <li className="pricelist-row">
                        <span>Aquarelle</span>
                        <span><strong><FormattedMessage id="rty.cena1" /></strong></span>
                      </li>
                      <li className="pricelist-row">
                        <span>Fullshading</span>
                        <span><strong><FormattedMessage id="rty.cena2" /></strong></span>
                      </li>
                      <li className="pricelist-row">
                        <span>Nude Lips</span>
                        <span><strong><FormattedMessage id="rty.cena3" /></strong></span>
                      </li>
                      <li className="pricelist-row">
                        <span><FormattedMessage id="data.oziveni" /></span>
                        <span><strong><FormattedMessage id="rty.oziveni" /></strong><FormattedMessage id="data.pouze" /></span>
                      </li>
                    </ul>
                  </li>
                  <li><FormattedMessage id="data.zahrnuje" />
                    <ul>
                      <li><FormattedMessage id="rty.zahrnuje1" /></li>
                      <li><FormattedMessage id="rty.zahrnuje2" /></li>
                      <li><FormattedMessage id="rty.zahrnuje3" /></li>
                      <li><FormattedMessage id="rty.zahrnuje4" /></li>
                      <li><FormattedMessage id="rty.zahrnuje5" /></li>
                      <li><FormattedMessage id="rty.zahrnuje6" /></li>
                      <li><FormattedMessage id="rty.zahrnuje7" /></li>
                      <li><FormattedMessage id="rty.zahrnuje8" /></li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <br /><br />
            <h3><FormattedMessage id="data.neprehlednete" /></h3>
            <ul>
              <li><FormattedMessage id="rty.neprehlednete1" /></li>
              <li><FormattedMessage id="data.neprehlednete2" /></li>
              <li><FormattedMessage id="data.neprehlednete3" /></li>
              <li><FormattedMessage id="data.neprehlednete4" /></li>
              <li><FormattedMessage id="data.neprehlednete5" /></li>
            </ul>
          </div>
        </div>
      </section>  
      <Gallery lower="rty" text={intl.formatMessage({ id: "rty.title" })} count={27} left={false} />
      <Video lower="rty" text={intl.formatMessage({ id: "rty.title" })} count={2} />
    </>
  );
};
