const baseStrings = {
  /** Routes */
  "routes.home": "/",
  "routes.omne": "/o-mne",
  "routes.pudroveoboci": "/pudrove-oboci",
  "routes.rty": "/rty",
  "routes.ocnilinky": "/ocni-linky",
  "routes.miniaquarelletattoo": "/mini-aquarelle-tattoo",
  "routes.odstranenipmu": "/odstraneni-pmu",
  "routes.nastrelovaninausnic": "/nastrelovani-nausnic",
  "routes.cenik": "/cenik",
  "routes.darkovepoukazy": "/darkove-poukazy",
  "routes.kontakt": "/kontakt",
  "routes.formularodeslan": "/formular-odeslan",

  /** Data */
  "data.lang": "cs",
  "data.dulezite": "Důležité informace",
  "data.co": "Co o mně říkají?",
  "data.fotogalerie": "Fotogalerie",
  "data.video": "Video",
  "data.predapo": "Před a po",
  "data.najit": "Najít cestu",
  "data.trasa": "Trasa",
  "data.oziveni": "Oživení do 2 let",
  "data.pouze": " (platí pouze pro mé práce)",
  "data.zahrnuje": "Cena zahrnuje",
  "data.neprehlednete": "Nepřehlédněte!",
  "data.neprehlednete1": "Mé milé klientky, při objednání si s Vámi domlouvám zároveň termín korekce a to hlavně proto, abyste na ni přišly v tu nejvhodnější dobu a také s ohledem na obsazenost termínů. Pokud budete chtít termín změnit, tak, prosím, počítejte s tím, že další volný termín může být za delší dobu a tetované místo už bude potřebovat více úprav. Zbytečně tím můžete snižovat kvalitu vyhojených výsledků. Proto Vás vždy prosím, abyste si daný termín řádně promyslely, abychom předešly zbytečným komplikacím.",
  "data.neprehlednete2": "Pokud budete přeci jen potřebovat změnit termín, učiňte tak nejdéle 48 hodin před původním termínem korekce. V opačném případě Vám bude ke korekci účtována navíc částka 500 Kč. Není to nic osobního, jen jsem v tu chvíli na Vaši korekci potřebovala dvojnásobek již započteného času. Pevně věřím, že mi rozumíte.",
  "data.neprehlednete3": "Cena korekce platí pouze pro korekce uskutečněné do 3 měsíců od první aplikace. Pokud budete korekci potřebovat později, cena může být navýšena.",
  "data.neprehlednete4": "Vyhrazuji si právo na výběr zálohy za objednanou proceduru, která je nevratná, pokud změnu termínu neohlásíte nejpozději 48 hodin předem.",
  "data.neprehlednete5": "Platba v hotovosti nebo QR kódem!",
  "data.vrattese": "Vraťte se, prosím, na ",
  "data.uvodnistranku": "úvodní stránku",
  "data.uvodnistranka": "Úvodní stránka",
  "data.zeptejtese": "Zeptejte se",
  "data.jednicky": "Jedničky na webové technologie",

  /** WhatsApp */
  "wa.status": "Od pondělí do pátku, 9:00 - 17:00",
  "wa.chat": "Dobrý den, jak Vám mohu pomoci?",
  "wa.placeholder": "Napište zprávu",

  /** Homepage */
  "home.title": "Úvod",
  "home.description": "Veronika Zelenková, Masarykova tř. 2475/57, 415 01 Teplice, Ústecký kraj. Permanentní make-up je tetování speciálními pigmenty určených do oblasti obličeje jako jsou rty, oční linky a pudrové obočí. Připravuji pro Vás také metodu Hair Stroke, která je šetrnější než microblading obočí a napodobuje vzhled laminace obočí.",
  "home.bannertitle": "Chcete se objednat?",
  "home.bannertext": "Volejte na telefonní číslo",
  "home.permanent": "Permanentní make-up",
  "home.text1": "Permanentní make-up, jak už sám název napovídá, je trvalý make-up. Ale za trvalý se považuje jen oproti klasickému líčení. Správně provedený permanentní make-up by měl časem z pokožky přirozeně vymizet a Vy byste tak měla mít možnost se znovu o podstoupení služby rozhodnout sama.",
  "home.text2": "Berte, prosím, na vědomí, že permanentní make-up není náhražka za klasické líčení. I když jej spousta žen tak používá, ale měl by být jen jejím podkladem. Žena by měla mít takový PMU, při kterém bude i odlíčená vypadat krásně přirozeně.",
  "home.text3": "Pokud se rozhodnete pro PMU, tak, prosím, respektujte, že vše má svá pravidla, která musíte dodržovat Vy i já. Odměnou Vám pak bude naturální vzhled i bez líčení. Svěží a mladistvější výraz, ušetřený čas i peníze při denním líčení.",
  "home.text4": "Upravená žena se vždy cítí sebevědomě a to vypozoruje i její okolí, a tak k ní i přistupuje. Tak si to dopřejte a vychutnejte.",
  "home.subtitle": "Co Vám všechno nabízím?",

  /** O mně */
  "omne.title": "O mně",
  "omne.description": "Jmenuji se Veronika Zelenková a PMU se věnuji již čtyři roky a za tu dobu jsem prošla různými školeními u známých osobností jako je Paulina Osinkowska, Christina Pidlozna z Academy-S a Veronika Fleiš z Fleiš Academy, kde jsem Gold Artist a získala jsem také spoustu certifikátů.",
  "omne.text1": "Moje jméno je Veronika Zelenková, s permanentním make-upem jsem se poprvé setkala v roce 2016 a od té doby se tetování stalo mou posedlostí.",
  "omne.text2": "Začala jsem se zajímat o různé školící akademie a o nejslavnější guru v PMU. Proto jsem absolvovala mnoho kurzů a školení, mj. u Pauliny Osinkowské, dále Christiny Pidlozne z Academy-S Sviatoslava Otchenashe a u Verici Blasko.",
  "omne.text3": "Navázala jsem Masterclassem u Ekateriny Antropové v Bratislavě a dále kurzem Hairstroke u Veroniky Petrás - La Majja.",
  "omne.text4": "Poté jsem studovala speciální techniku tetování rtů Frozen Lips u Agnes Permanent a pokračovala jsem zdokonalováním pudrové techniky u Nikoly Sedláčkové - IM Brows a Lucie Schreiber.",
  "omne.text5": "Navíc jsem se zúčastnila dvoudenního kurzu Perfect Lips u Maryny Bondar - La Skin a dále školení firmy Kwadron, kde mj. prezentovala svou techniku rtů i Marta Ziolkowska.",
  "omne.text6": "Úžasné školení speciální techniky rtů předvedla i Dolores Srok, které jsem měla tu čest asistovat. Spoustu vědomostí jsem získala také na workshopu o pigmentologii firmy Etalon v Praze.",
  "omne.text7": "Krásnou techniku pudrového obočí Velvet Brows jsem se školila u Magdaleny Telesińské, výherkyně světového šampionátu Wulop 2023.",
  "omne.text8": "V oblasti odstraňování PMU jsme absolvovala školení na všechny existující techniky. Roztoky solné, kyselinové a laser.",
  "omne.text9": "Také se specializuji na nastřelování náušnic pod anglickou firmou Estelle, kterou považuji za nejvíce profesionální firmu na trhu v oblasti piercingu.",
  "omne.text10": "Ta nejdůležitejší školení jsem ale absolvovala ve Fleiš Academy u Veroniky Fleiš, pro mne mistra v oboru. Zde jsem dosáhla titulů Artist, Silver Artist, Gold Artist, Master Assistant a nakonec té nejvyšší možné mety Master.",
  "omne.text11": "Jsem také jedna z mála držitelů log pro speciání autorské techniky, jako jsou Nude Lips a Smokey Eyes.",
  "omne.text12": "Mám za sebou mnoho vzdělávacích kongresů nejen u nás v Čechách - například to byl PMU LIVE pořádaný Leou Matyi nebo série Czech PMU Congress, které pořádá Veronika Fleiš, ale i v zahraničí. Např. v Mnichově a v Arménii jsem vystupovala jako NONSTOP SPEAKER a předváděla zde svou techniku tetování rtů Fruity Lips. Tuto techniku jsem prezentovala také na Wulop 2023 v Irsku, kam mě pozvala Aleksandra Pawlak.",
  "omne.text13": "Pro Fleiš Academy v současné době ještě navíc školím jak v České republice, tak na Slovensku.",
  "omne.text14": "Velkým mezníkem v mé kariéře bylo ohodnocení americkou firmou SKILLZ a nabídnutí spolupráce. Stala jsem se sponzorovaným SKILLZ MASTEREM a jsem na to moc pyšná.",
  "omne.text15": "V neposlední řadě jsem měla skvělou příležitost zúčastnit se prvního československého online PMU šampionátu a to v kategorii pudrové obočí, kde jsem obsadila 3. místo a v kategorii mikropigmentace rtů, kde jsem obsadila 2. místo.",
  "omne.text16": "Ale mým největším osobním úspěchem je účast na mezinárodním online šampionátu International Beauty Festival California USA San Francisco 2024, kde jsem obsadila hned 1. i 2. místo v kategorii Master - Lipstick Effect.",
  "omne.text17": "Úspěch, který mě také bude dlouho hřát u srdce je 2. místo ve World Beauty Champ v té nejvyšší kategorii Professional.",
  "omne.text18": "Za skvělé umístění považuji také 3. místo v mezinárodním online šampionátu Global Beauty Festival v kategorii Lipstick Effect.",
  "omne.text19": "V další neméně prestižní mezinárodní soutěži WBC Beauty Champ 2024 jsem obsadila úžasné 3. místo a to už v nejvyšší kategorii Professional - Permanent Lip Makeup.",
  "omne.text20": "Urvala jsem i 2. místo v soutěži PMU LIVE v kategorii Master Realistic Lips.",
  "omne.text21": "A co je pro mě opravdu velkou poctou, je ocenění od světové společnosti BEAUTY AWARDS, od které jsem získala 2 tituly - TOP 10 PERMANENT MAKEUP STYLISTS a TOP MASTER.",
  "omne.text22": "Tím to ale pro mne zdaleka nekončí. Neustále se vzdělávám a vzdělávat budu. Chci pro Vás jen to nejlepší.",
  "omne.text23": "Baví mě to, svou práci miluju a je to má vášeň!",

  /** Pudrové obočí */
  "pudroveoboci.title": "Pudrové obočí",
  "pudroveoboci.description": "Permanentní make-up Veronika Teplice nabízí tetování obočí technikou pudrování.",
  "pudroveoboci.co": "Co je pudrové obočí?",
  "pudroveoboci.co1": "Je to inovativní metoda mikropigmentace kůže v oblasti nadočnicových oblouků.",
  "pudroveoboci.co2": "Díky této technice vypadá obočí krásně přirozeně.",
  "pudroveoboci.co3": "Obočí se pomocí jehly a speciálních pigmentů jen lehce definuje a co nejpřirozeněji se vystínuje tak, aby co nejlépe odpovídal reálnému obočí.",
  "pudroveoboci.jak": "Jak bude probíhat má návštěva?",
  "pudroveoboci.jak1": "Vyměřuji tvar tak, aby co nejvíce ladil s Vaším obličejem.",
  "pudroveoboci.jak2": "Pamatujte na to, že obočí by Vás mělo rozjasnit a omladit.",
  "pudroveoboci.jak3": "Poté zvolíme pigment hodící se k Vašemu typu pleti.",
  "pudroveoboci.jak4": "Po ukončení aplikace místo ošetřím a klientce předám pokyny k péči v tištěné podobě.",
  "pudroveoboci.jak5": "Celá procedura trvá přibližně 90 - 120 minut.",
  "pudroveoboci.vedet": "Co bych měla před zákrokem vědět?",
  "pudroveoboci.vedet1": "Na proceduru přijďte bez doprovodu. Tím je myšleno bez dětí, kamarádů, zvířátek, kočárků a dalších rodinných i nerodinných příslušníků. :-)",
  "pudroveoboci.vedet2": "Tetování obočí má, co se týká tvaru a barvy, svá pravidla, která při své práci vždy dodržuji. Pokud nejste ochotná tato pravidla respektovat, nebudu Vám moci vyhovět. Tím je myšleno, že určitě nebudu dělat extravagantní tvary nebo na obočí používat černou barvu.",
  "pudroveoboci.vedet3": "Naším cílem by mělo být dosáhnout co nejpřirozenějšího vzhledu.",
  "pudroveoboci.vedet4": "Pokud již máte vytetované nějaké obočí a je jedno, jak je staré a jak je viditelné a není dělané mnou, tak to při objednávání musíte předem nahlásit.",
  "pudroveoboci.vedet5": "Tetování obočí je proces, ne jednorázová akce. Takže pokud není obočí vykorektované, tak není dokončené a dokonalé. Tudíž, pokud máte nějakou událost, na kterou chcete mít hotové obočí, počítejte i s časem vyhojení a korekcí.",
  "pudroveoboci.vedet6": "Na proceduru přijďte odpočatá, najezená a napitá.",
  "pudroveoboci.vedet7": "V den procedury nepijte alkohol, kávu a neberte analgetika.",
  "pudroveoboci.pro": "Pro koho není aplikace vhodná?",
  "pudroveoboci.kontraindikace": "Pokud trpíte jakoukoliv z níže popsaných kontraindikací, není možné zákrok provést:",
  "pudroveoboci.kontraindikace1": "cukrovka s aplikací inzulínu",
  "pudroveoboci.kontraindikace2": "kardiostimulátor",
  "pudroveoboci.kontraindikace3": "srdeční choroby",
  "pudroveoboci.kontraindikace4": "cévní choroby",
  "pudroveoboci.kontraindikace5": "snížená srážlivost krve",
  "pudroveoboci.kontraindikace6": "léky na ředění krve",
  "pudroveoboci.kontraindikace7": "snížená imunita",
  "pudroveoboci.kontraindikace8": "užívání antibiotik",
  "pudroveoboci.kontraindikace9": "horečnaté stavy",
  "pudroveoboci.kontraindikace10": "akutní onemocnění či nachlazení",
  "pudroveoboci.kontraindikace11": "solární erytém (spálení)",
  "pudroveoboci.kontraindikace12": "kožní nemoc - dermatitida, vyrážka (včetně akutní alergické) apod.",
  "pudroveoboci.kontraindikace13": "rakovina v aktivním stádiu či rakovina kůže v jakékoliv fázi",
  "pudroveoboci.kontraindikace14": "epilepsie",
  "pudroveoboci.kontraindikace15": "HIV či jiná přenosná nemoc",
  "pudroveoboci.kontraindikace16": "alergie na kosmetické látky, alergie na anestetika, alergie na prvotně aplikovaný pigment",
  "pudroveoboci.kontraindikace17": "těhotenství a kojení",
  "pudroveoboci.kontraindikace18": "příliš kapilární pleť",
  "pudroveoboci.kontraindikace19": "keloidní či hypertrofické jizvy",
  "pudroveoboci.kontraindikace20": "psychické onemocnění či celková psychická nepohoda či úzkost",
  "pudroveoboci.boli": "Bolí to?",
  "pudroveoboci.boli1": "Mikropigmentace obočí není bolestivou procedurou.",
  "pudroveoboci.pecovat": "Jak pečovat o obočí?",
  "pudroveoboci.pecovat1": "Ať už se rozhodnete pro jakoukoliv proceduru, při které je porušována integrita kůže, tak berte, prosím, na vědomí, že se k ní vážou různá pravidla a omezení. Nedodržením pokynů můžete narušit proces hojení a negativně tak ovlivnit výsledný efekt.",
  "pudroveoboci.pecovat2": "14 dní po proceduře NENAVŠTĚVUJTE sauny, solária, bazény a koupaliště, přímé slunce, fitness centra nebo jiná sportoviště. Na slunci můžeme použít klobouk nebo kšiltovku. Sluneční brýle NE, ty jako ochrana NESTAČÍ!",
  "pudroveoboci.pecovat3": "Během vyhojování se barva obočí může malinko měnit. NEPANIKAŘÍME! To je naprosto běžné. Při korekci se vše doladí. Od toho korekce jsou.",
  "pudroveoboci.pecovat4": "Podrobné pokyny k péči o ošetřené místo ode mne obdržíte v písemné podobě.",
  "pudroveoboci.kolik": "Kolik to stojí?",
  "pudroveoboci.cena": "4500 Kč + korekce 1000 Kč + luxusní balíček s péčí o obočí ZDARMA",
  "pudroveoboci.oziveni": "3000 Kč + korekce 1000 Kč + mastička ZDARMA",
  "pudroveoboci.zahrnuje1": "Konzultaci o průběhu procedury, kde se můžete zeptat na vše, co Vás zajímá.",
  "pudroveoboci.zahrnuje2": "Poté zhodnocení a vyměření tvaru tak, aby co nejvíce podtrhoval Vaši krásu.",
  "pudroveoboci.zahrnuje3": "Dále výběr toho nejvhodnějšího pigmentu pro co nejpřirozenější vzhled a samozřejmě v té nejvyšší kvalitě.",
  "pudroveoboci.zahrnuje4": "Samotnou proceduru, kde se budu snažit, abyste se cítila co nejvíce pohodlně.",
  "pudroveoboci.zahrnuje5": "Také předání informací ohledně následné péče.",
  "pudroveoboci.zahrnuje6": "A jako bonus luxusní balíček se všemi produkty, které Vám pomohou k získání toho nejlepšího vyhojeného výsledku.",
  "pudroveoboci.zahrnuje7": "Samozřejmostí jsou detailní pokyny k péči v tištěné podobě.",
  "pudroveoboci.zahrnuje8": "A v neposlední řadě následné poradenství v případě dotazů.",
  "pudroveoboci.neprehlednete1": "Mé milé klientky, při objednání si s Vámi domlouvám zároveň termín korekce a to hlavně proto, abyste na ni přišly v tu nejvhodnější dobu a také s ohledem na obsazenost termínů. Pokud budete chtít termín změnit, tak, prosím, počítejte s tím, že další volný termín může být za delší dobu a obočí už bude potřebovat více úprav. Zbytečně tím můžete snižovat kvalitu vyhojených výsledků. Proto Vás vždy prosím, abyste si daný termín řádně promyslely, abychom předešly zbytečným komplikacím.",

  /** Rty */
  "rty.title": "Rty",
  "rty.description": "Permanentní make-up Veronika Teplice nabízí tetování rtů technikou Aquarelle, Fullshading a novinkou Nude Lips.",
  "rty.co": "Co je mikropigmentace rtů?",
  "rty.co1": "Je to procedura, při které se pečlivě zapravuje speciální pigment do oblasti rtů.",
  "rty.co2": "Vaše rtíky tak budou vypadat více výrazněji, šťavnatěji a svůdně i bez použití rtěnky.",
  "rty.co3": "Rtům můžeme pomocí barev dodat barevnější vzhled nebo jen vytvořit dojem zdravých, přírodně zabarvených rtů.",
  "rty.styly": "Jaké máme styly rtů?",
  "rty.styly1": "Rozlišujeme několik stylů tetování rtů podle toho, kam se pigment přesně umístí.",
  "rty.styly2": "Provádím tyto tři styly:",
  "rty.aquarelle1": "Jedná se o nejjemnější a nejžádanější styl tetování.",
  "rty.aquarelle2": "Díky této technice vypadají rty velmi přirozeně s jemným nádechem použitého pigmentu, kterým se rty jen jemně popráší.",
  "rty.aquarelle3": "Vytvoříme tak na puse efekt jemně zabarveného lesku.",
  "rty.fullshading": "Tímto způsobem jsou celé rty rovnoměrně vybarvené s tím, že pigment je zapravován do rtu mnohem hustěji a pusa působí celkově sytým dojmem.",
  "rty.nudelips1": "Je to autorská technika Veroniky Fleiš a v České republice i v EU může její logo používat jen pár stylistek.",
  "rty.nudelips2": "Jsem moc ráda, že Vám ji mohu nabídnout právě já.",
  "rty.nudelips3": "Tato technika je nejvhodnější na světlé až mdlé rty, přičemž se velmi speciálním postupem zabarvují do krásných přírodních odstínů, aby se tak podpořil jen jejich naturální vzhled.",
  "rty.jak": "Jak bude probíhat má návštěva?",
  "rty.jak1": "S klientkou nejdříve probereme, jaký styl je pro ni nejvhodnější.",
  "rty.jak2": "Spolu pak vybereme pigment tak, aby se co nejlépe hodil k jejímu typu pleti.",
  "rty.jak3": "Provedeme nákres, jemně si načrtnu konturu bez anestetik.",
  "rty.jak4": "Poté nanesu anestetika a dostínuji do požadovaných odstínů celé rty.",
  "rty.jak5": "Po ukončení aplikace rty ošetřím, klientce předám pokyny k péči v tištěné podobě a mastičku pro nejlepší hojení.",
  "rty.jak6": "Celá procedura trvá přibližně 120 - 180 minut.",
  "rty.vedet": "Co bych měla před zákrokem vědět?",
  "rty.vedet1": "Na proceduru přijďte bez doprovodu. Tím je myšleno bez dětí, kamarádů, zvířátek, kočárků a dalších rodinných i nerodinných příslušníků. :-)",
  "rty.vedet2": "Pokud trpíte na opary nebo jste již v minulosti opar měla, je doporučeno užívat lék Herpesin 400, který začněte užívat nejméně 3 dny před procedurou.",
  "rty.vedet3": "Tablety Herpesin 400 jsou na lékařský předpis a nelze je nahradit mastičkou Herpesin.",
  "rty.vedet4": "Kupte si lanolin nejlépe v čisté přírodní podobě a intenzivně mazejte rty až do procedury. Do procedury nepoužívejte žádné jiné produkty.",
  "rty.vedet5": "Na proceduru přijďte odpočatá, najezená a napitá.",
  "rty.vedet6": "V den procedury nepijte alkohol, kávu, neberte analgetika a před procedurou nekuřte.",
  "rty.pro": "Pro koho není aplikace vhodná?",
  "rty.pro1": "Pro klientky, které se nevyhnou dennímu nošení roušek.",
  "rty.kontraindikace": "Pokud trpíte jakoukoliv z níže popsaných kontraindikací, není možné zákrok provést:",
  "rty.kontraindikace1": "cukrovka s aplikací inzulínu",
  "rty.kontraindikace2": "kardiostimulátor",
  "rty.kontraindikace3": "srdeční choroby",
  "rty.kontraindikace4": "cévní choroby",
  "rty.kontraindikace5": "snížená srážlivost krve",
  "rty.kontraindikace6": "léky na ředění krve",
  "rty.kontraindikace7": "snížená imunita",
  "rty.kontraindikace8": "užívání antibiotik",
  "rty.kontraindikace9": "horečnaté stavy",
  "rty.kontraindikace10": "akutní onemocnění či nachlazení",
  "rty.kontraindikace11": "kožní nemoc - dermatitida, vyrážka (včetně akutní alergické) apod.",
  "rty.kontraindikace12": "rakovina v aktivním stádiu či rakovina kůže v jakékoliv fázi",
  "rty.kontraindikace13": "epilepsie",
  "rty.kontraindikace14": "HIV či jiná přenosná nemoc",
  "rty.kontraindikace15": "alergie na kosmetické látky, alergie na anestetika, alergie na prvotně aplikovaný pigment",
  "rty.kontraindikace16": "těhotenství a kojení",
  "rty.kontraindikace17": "příliš kapilární pleť",
  "rty.kontraindikace18": "keloidní či hypertrofické jizvy",
  "rty.kontraindikace19": "psychické onemocnění či celková psychická nepohoda či úzkost",
  "rty.boli": "Bolí to?",
  "rty.boli1": "Tetování rtů se považuje za bolestivé, ale není tomu tak.",
  "rty.boli2": "V případě nepohodlí jsou použita anestetika.",
  "rty.pecovat": "Jak pečovat o rty?",
  "rty.pecovat1": "Ať už se rozhodnete pro jakoukoliv proceduru, při které je porušována integrita kůže, tak berte, prosím, na vědomí, že se k ní vážou různá pravidla a omezení. Nedodržením pokynů můžete narušit proces hojení a negativně tak ovlivnit výsledný efekt.",
  "rty.pecovat2": "14 dní po proceduře NENAVŠTĚVUJTE sauny, solária, bazény a koupaliště, přímé slunce, fitness centra nebo jiná sportoviště.",
  "rty.pecovat3": "Podrobné pokyny k péči a pomůcky pro správné vyhojení rtů ode mě obdržíte v písemné podobě.",
  "rty.kolik": "Kolik to stojí?",
  "rty.cena1": "5000 Kč + korekce 1000 Kč + luxusní balíček s péčí o rty ZDARMA",
  "rty.cena2": "5000 Kč + korekce 1000 Kč + luxusní balíček s péčí o rty ZDARMA",
  "rty.cena3": "5000 Kč + korekce 1000 Kč + luxusní balíček s péčí o rty ZDARMA",
  "rty.oziveni": "3500 Kč + korekce 1000 Kč + mastička ZDARMA",
  "rty.zahrnuje1": "Konzultaci o průběhu procedury, kde se můžete zeptat na vše, co Vás zajímá.",
  "rty.zahrnuje2": "Poté zhodnocení a vyměření tvaru tak, aby co nejvíce podtrhoval Vaši krásu.",
  "rty.zahrnuje3": "Dále výběr toho nejvhodnějšího pigmentu pro co nejpřirozenější vzhled a samozřejmě v té nejvyšší kvalitě.",
  "rty.zahrnuje4": "Samotnou proceduru, kde se budu snažit, abyste se cítila co nejvíce pohodlně.",
  "rty.zahrnuje5": "Také předání informací ohledně následné péče.",
  "rty.zahrnuje6": "A jako bonus luxusní balíček se všemi produkty, které Vám pomohou k získání toho nejlepšího vyhojeného výsledku.",
  "rty.zahrnuje7": "Samozřejmostí jsou detailní pokyny k péči v tištěné podobě.",
  "rty.zahrnuje8": "A v neposlední řadě následné poradenství v případě dotazů.",
  "rty.neprehlednete1": "Mé milé klientky, při objednání si s Vámi domlouvám zároveň termín korekce a to hlavně proto, abyste na ni přišly v tu nejvhodnější dobu a také s ohledem na obsazenost termínů. Pokud budete chtít termín změnit, tak, prosím, počítejte s tím, že další volný termín může být za delší dobu a rty už budou potřebovat více úprav. Zbytečně tím můžete snižovat kvalitu vyhojených výsledků. Proto Vás vždy prosím, abyste si daný termín řádně promyslely, abychom předešly zbytečným komplikacím.",

  /** Oční linky */
  "ocnilinky.title": "Oční linky",
  "ocnilinky.description": "Permanentní make-up Veronika Teplice nabízí techniku tetování očních linek jak v řasové linii, tak i stínované linky v řasové linii.",
  "ocnilinky.co": "Co je mikropigmentace očních linek?",
  "ocnilinky.co1": "Je to precizní zapravování speciálního pigmentu pod kůži, oblasti řas a horních víček.",
  "ocnilinky.co2": "Díky mikropigmentaci očních linek vypadá oko výrazněji, i když nejste právě nalíčená.",
  "ocnilinky.co3": "Jsou tak výhodou pro ženy, které se nelíčí vůbec nebo mají své řasy velmi světlé.",
  "ocnilinky.co4": "Tetování linek vytváří dojem hustších a černějších řas.",
  "ocnilinky.co5": "Z estetických důvodů provádím tetování očních linek pouze u horních víček.",
  "ocnilinky.jak": "Jak bude probíhat má návštěva?",
  "ocnilinky.jak1": "Nejdříve si s kientkou domluvíme styl, který se k ní bude nejvíce hodit, musíme brát na vědomí také stav kůže okolo oka a výraz klientky.",
  "ocnilinky.jak2": "Zde bereme v úvahu, že nám linky mají oko rozzářit a pozvednout a přihlížíme i k faktu, že kůže v okolí oka bude časem klesat.",
  "ocnilinky.jak3": "Po domluvě aplikuji anestetika, pak aplikuji barvu, místo aplikace ošetřím a nakonec klientce dám pokyny k péči v tištěné podobě.",
  "ocnilinky.jak4": "Celá procedura trvá přibližně 60 - 90 minut.",
  "ocnilinky.vedet": "Co bych měla před zákrokem vědět?",
  "ocnilinky.vedet1": "Na proceduru přijďte bez doprovodu. Tím je myšleno bez dětí, kamarádů, zvířátek, kočárků a dalších rodinných i nerodinných příslušníků. :-)",
  "ocnilinky.vedet2": "Při mikropigmentaci očních linek nesmíte mít nalepené žádné umělé řasy a to minimálně týden.",
  "ocnilinky.vedet3": "Na zákrok byste měla přijít odlíčená.",
  "ocnilinky.vedet4": "Nesmíte mít při aplikaci kontaktní čočky.",
  "ocnilinky.vedet5": "Minimálně tři týdny před zákrokem nepoužívejte žádná séra podporující růst řas.",
  "ocnilinky.vedet6": "Přijďte dostatečně odpočatá a vyspalá bez otoku očí.",
  "ocnilinky.vedet7": "U očních linek je pro dosažení sytého efektu vždy nutná korekce.",
  "ocnilinky.vedet8": "Na proceduru přijďte najezená a napitá.",
  "ocnilinky.vedet9": "V den procedury nepijte alkohol, kávu, neberte analgetika a nekuřte.",
  "ocnilinky.pro": "Pro koho není aplikace vhodná?",
  "ocnilinky.kontraindikace": "Pokud trpíte jakoukoliv z níže popsaných kontraindikací, není možné zákrok provést:",
  "ocnilinky.kontraindikace1": "cukrovka s aplikací inzulínu",
  "ocnilinky.kontraindikace2": "kardiostimulátor",
  "ocnilinky.kontraindikace3": "srdeční choroby",
  "ocnilinky.kontraindikace4": "cévní choroby",
  "ocnilinky.kontraindikace5": "snížená srážlivost krve",
  "ocnilinky.kontraindikace6": "léky na ředění krve",
  "ocnilinky.kontraindikace7": "snížená imunita",
  "ocnilinky.kontraindikace8": "užívání antibiotik",
  "ocnilinky.kontraindikace9": "horečnaté stavy",
  "ocnilinky.kontraindikace10": "akutní onemocnění či nachlazení",
  "ocnilinky.kontraindikace11": "solární erytém (spálení)",
  "ocnilinky.kontraindikace12": "kožní nemoc - dermatitida, vyrážka (včetně akutní alergické) apod.",
  "ocnilinky.kontraindikace13": "rakovina v aktivním stádiu či rakovina kůže v jakékoliv fázi",
  "ocnilinky.kontraindikace14": "epilepsie",
  "ocnilinky.kontraindikace15": "HIV či jiná přenosná nemoc",
  "ocnilinky.kontraindikace16": "alergie na kosmetické látky, alergie na anestetika, alergie na prvotně aplikovaný pigment",
  "ocnilinky.kontraindikace17": "těhotenství a kojení",
  "ocnilinky.kontraindikace18": "příliš kapilární pleť",
  "ocnilinky.kontraindikace19": "keloidní či hypertrofické jizvy",
  "ocnilinky.kontraindikace20": "psychické onemocnění či celková psychická nepohoda či úzkost",
  "ocnilinky.boli": "Bolí to?",
  "ocnilinky.boli1": "Před i během zákroku používám dva druhy anestetik a sanžím se, aby pro klientku byl průběh co nejvíce pohodlný.",
  "ocnilinky.boli2": "Pigment zapracuji šetrně a stejně tak se chovám i k okolí oka, jelikož je zde velice citlivá kůže.",
  "ocnilinky.pecovat": "Jak pečovat o linky?",
  "ocnilinky.pecovat1": "Ať už se rozhodnete pro jakoukoliv proceduru, při které je porušována integrita kůže, tak berte, prosím, na vědomí, že se k ní vážou různá pravidla a omezení. Nedodržením pokynů můžete narušit proces hojení a negativně tak ovlivnit výsledný efekt.",
  "ocnilinky.pecovat2": "14 dní po proceduře NENAVŠTĚVUJTE sauny, solária, bazény a koupaliště, přímé slunce, fitness centra nebo jiná sportoviště.",
  "ocnilinky.pecovat3": "Podrobné pokyny k péči o ošetřené místo ode mne obdržíte v písemné podobě.",
  "ocnilinky.kolik": "Kolik to stojí?",
  "ocnilinky.natural": "Natural - pouze v řasové linii",
  "ocnilinky.cena1": "3500 Kč + korekce 1000 Kč",
  "ocnilinky.zahrnuje1": "Konzultaci o průběhu procedury, kde se můžete zeptat na vše, co Vás zajímá.",
  "ocnilinky.zahrnuje2": "Dále výběr toho nejvhodnějšího pigmentu pro co nejpřirozenější vzhled a samozřejmě v té nejvyšší kvalitě.",
  "ocnilinky.zahrnuje3": "Samotnou proceduru, kde se budu snažit, abyste se cítila co nejvíce pohodlně.",
  "ocnilinky.zahrnuje4": "Také předání informací ohledně následné péče.",
  "ocnilinky.zahrnuje5": "Samozřejmostí jsou detailní pokyny k péči v tištěné podobě.",
  "ocnilinky.zahrnuje6": "A v neposlední řadě následné poradenství v případě dotazů.",
  "ocnilinky.neprehlednete1": "Mé milé klientky, při objednání si s Vámi domlouvám zároveň termín korekce a to hlavně proto, abyste na ni přišly v tu nejvhodnější dobu a také s ohledem na obsazenost termínů. Pokud budete chtít termín změnit, tak, prosím, počítejte s tím, že další volný termín může být za delší dobu a linky už budou potřebovat více úprav. Zbytečně tím můžete snižovat kvalitu vyhojených výsledků. Proto Vás vždy prosím, abyste si daný termín řádně promyslely, abychom předešly zbytečným komplikacím.",

  /** Mini Aquarelle Tattoo */
  "miniaquarelletattoo.title": "Mini Aquarelle Tattoo",
  "miniaquarelletattoo.description": "Permanentní make-up Veronika Teplice nabízí techniku tělového barevného tetování Mini Aquarelle Tattoo.",
  "miniaquarelletattoo.co": "Co je Mini Aquarelle Tattoo?",
  "miniaquarelletattoo.co1": "Je to krásná metoda tetování pocházející z Ameriky.",
  "miniaquarelletattoo.co2": "Vyznačuje se černými liniemi, které doplňují výrazné a živé barvy připomínající akvarelové barvy.",
  "miniaquarelletattoo.co3": "Ve světě ale i u nás se těší čím dál tím větší oblibě.",
  "miniaquarelletattoo.co4": "Je to hravé, zábavné, ale i umělecké tetování.",
  "miniaquarelletattoo.pro": "Pro koho není aplikace vhodná?",
  "miniaquarelletattoo.kontraindikace": "Pokud trpíte jakoukoliv z níže popsaných kontraindikací, není možné zákrok provést:",
  "miniaquarelletattoo.kontraindikace1": "cukrovka s aplikací inzulínu",
  "miniaquarelletattoo.kontraindikace2": "kardiostimulátor",
  "miniaquarelletattoo.kontraindikace3": "srdeční choroby",
  "miniaquarelletattoo.kontraindikace4": "cévní choroby",
  "miniaquarelletattoo.kontraindikace5": "snížená srážlivost krve",
  "miniaquarelletattoo.kontraindikace6": "léky na ředění krve",
  "miniaquarelletattoo.kontraindikace7": "snížená imunita",
  "miniaquarelletattoo.kontraindikace8": "užívání antibiotik",
  "miniaquarelletattoo.kontraindikace9": "horečnaté stavy",
  "miniaquarelletattoo.kontraindikace10": "akutní onemocnění či nachlazení",
  "miniaquarelletattoo.kontraindikace11": "solární erytém (spálení)",
  "miniaquarelletattoo.kontraindikace12": "kožní nemoc - dermatitida, vyrážka (včetně akutní alergické) apod.",
  "miniaquarelletattoo.kontraindikace13": "rakovina v aktivním stádiu či rakovina kůže v jakékoliv fázi",
  "miniaquarelletattoo.kontraindikace14": "epilepsie",
  "miniaquarelletattoo.kontraindikace15": "HIV či jiná přenosná nemoc",
  "miniaquarelletattoo.kontraindikace16": "alergie na kosmetické látky, alergie na anestetika, alergie na prvotně aplikovaný pigment",
  "miniaquarelletattoo.kontraindikace17": "těhotenství a kojení",
  "miniaquarelletattoo.kontraindikace18": "příliš kapilární pleť",
  "miniaquarelletattoo.kontraindikace19": "keloidní či hypertrofické jizvy",
  "miniaquarelletattoo.kontraindikace20": "psychické onemocnění či celková psychická nepohoda či úzkost",
  "miniaquarelletattoo.pecovat": "Jak pečovat o své tetování?",
  "miniaquarelletattoo.pecovat1": "Ať už se rozhodnete pro jakoukoliv proceduru, při které je porušována integrita kůže, tak berte, prosím, na vědomí, že se k ní vážou různá pravidla a omezení. Nedodržením pokynů můžete narušit proces hojení a negativně tak ovlivnit výsledný efekt.",
  "miniaquarelletattoo.pecovat2": "Podrobné pokyny k péči o ošetřené místo ode mne obdržíte v písemné podobě.",
  "miniaquarelletattoo.kolik": "Kolik to stojí?",
  "miniaquarelletattoo.cena": "Cena i doba tetování se odvíjí od náročnosti a velikosti předlohy.",

  /** Odstranění PMU */
  "odstranenipmu.title": "Odstranění PMU",
  "odstranenipmu.description": "Permanentní make-up Veronika Teplice provádí odstranění tetování různými způsoby.",
  "odstranenipmu.co": "Co je odstranění PMU?",
  "odstranenipmu.co1": "Technik pro odstranění PMU je více. Roztoky (kyselinové nebo solné) nebo laser. Aplikace je odlišná, ale záměr stejný. Dostat z podkoží co nejvíce pigmentu. Roztoky jsou invazivní a používají se v oblastech, kde nelze použít laser.",
  "odstranenipmu.jak": "Jak bude probíhat má návštěva?",
  "odstranenipmu.jak1": "S klientkou se nejdříve domluvíme na místě, které bude chtít ošetřit.",
  "odstranenipmu.jak2": "Zvolíme nejvhodnější a nejefektivnější způsob odstranění.",
  "odstranenipmu.jak3": "Následně dostane klientka pokyny, jak pečovat o ošetřené místo a krém s SPF 50.",
  "odstranenipmu.vedet": "Co bych měla před zákrokem vědět?",
  "odstranenipmu.vedet1": "Na proceduru přijďte bez doprovodu. Tím je myšleno bez dětí, kamarádů, zvířátek, kočárků a dalších rodinných i nerodinných příslušníků. :-)",
  "odstranenipmu.vedet2": "Každé tetování je jiné a to samé platí i o jeho odstranění.",
  "odstranenipmu.vedet3": "Zbavení se pigmentu není tak jednoduché, jako je jeho zapravení.",
  "odstranenipmu.vedet4": "Je to proces, který potřebuje čas a trpělivost.",
  "odstranenipmu.vedet5": "Nikdo Vám nikdy nezaručí počet sezení a konečný výsledek.",
  "odstranenipmu.vedet6": "Vždy záleží na tom, jeké byly použity pigmenty nebo dokonce inkousty, do jaké hloubky byly zapraveny a jak dlouho už v kůži jsou.",
  "odstranenipmu.vedet7": "Samotný zákrok trvá přibližně 30 minut.",
  "odstranenipmu.pro": "Pro koho není aplikace vhodná?",
  "odstranenipmu.kontraindikace": "Pokud trpíte jakoukoliv z níže popsaných kontraindikací, není možné zákrok provést:",
  "odstranenipmu.kontraindikace1": "cukrovka s aplikací inzulínu",
  "odstranenipmu.kontraindikace2": "kardiostimulátor",
  "odstranenipmu.kontraindikace3": "srdeční choroby",
  "odstranenipmu.kontraindikace4": "cévní choroby",
  "odstranenipmu.kontraindikace5": "snížená srážlivost krve",
  "odstranenipmu.kontraindikace6": "léky na ředění krve",
  "odstranenipmu.kontraindikace7": "snížená imunita",
  "odstranenipmu.kontraindikace8": "užívání antibiotik",
  "odstranenipmu.kontraindikace9": "horečnaté stavy",
  "odstranenipmu.kontraindikace10": "akutní onemocnění či nachlazení",
  "odstranenipmu.kontraindikace11": "kožní nemoc - dermatitida, vyrážka (včetně akutní alergické) apod.",
  "odstranenipmu.kontraindikace12": "rakovina v aktivním stádiu či rakovina kůže v jakékoliv fázi",
  "odstranenipmu.kontraindikace13": "epilepsie",
  "odstranenipmu.kontraindikace14": "HIV či jiná přenosná nemoc",
  "odstranenipmu.kontraindikace15": "alergie na kosmetické látky, alergie na anestetika, alergie na prvotně aplikovaný pigment",
  "odstranenipmu.kontraindikace16": "těhotenství a kojení",
  "odstranenipmu.kontraindikace17": "příliš kapilární pleť",
  "odstranenipmu.kontraindikace18": "keloidní či hypertrofické jizvy",
  "odstranenipmu.kontraindikace19": "psychické onemocnění či celková psychická nepohoda či úzkost",
  "odstranenipmu.boli": "Bolí to?",
  "odstranenipmu.boli1": "Vždy se snažím, aby se mé klientky cítily co nejpohodlněji.",
  "odstranenipmu.boli2": "Vzhledem k rychlosti zákroku se nejedná o nijak nepříjemnou proceduru.",
  "odstranenipmu.boli3": "Po aplikaci můžete mít v oblasti odstranění 3 dny otok.",
  "odstranenipmu.pecovat": "Jak pečovat o ošetřené místo?",
  "odstranenipmu.pecovat1": "Místo po odstranění můžete chladit, ne však ledovat. Stačí 10 minut a nechat místo zase v klidu. Ošetřujte kůži krémem s SPF 50 a týden nevystavujte kůži vyšším teplotám. Od druhého dne se můžete líčit. Nedodržením pokynů můžete narušit proces hojení a negativně tak ovlivnit výsledný efekt.",
  "odstranenipmu.pecovat2": "Podrobné pokyny k péči o ošetřené místo ode mne obdržíte v písemné podobě.",
  "odstranenipmu.kolik": "Kolik to stojí?",
  "odstranenipmu.sezeni": "1 sezení",
  "odstranenipmu.cena1": "1000 Kč + houbička a krém s SPF 50 ZDARMA",
  "odstranenipmu.cena2": "Klientky, které už u mě podstoupily aplikaci obočí, mají odstranění ZDARMA.",
  "odstranenipmu.neprehlednete": "Pokud budete potřebovat změnit termín, učiňte tak nejdéle 48 hodin před původním termínem procedury. V opačném případě Vám bude k proceduře účtována navíc částka 300 Kč. Není to nic osobního, jen jsem v tu chvíli na Vaši proceduru potřebovala dvojnásobek již započteného času. Pevně věřím, že mi rozumíte.",
 
  /** Nastřelování náušnic */
  "nastrelovaninausnic.title": "Nastřelování náušnic",
  "nastrelovaninausnic.description": "Permanentní make-up Veronika Teplice nabízí bezbolestné nastřelení náušnic od firmy Estelle v provedení z antialergenní leštěné oceli nebo pozlacené dvaceti čtyř karátovým zlatem a osazenými kamínky Swarovski.",
  "nastrelovaninausnic.co": "Co je Estelle?",
  "nastrelovaninausnic.co1": "Estelle je firma sídlící ve Velké Británii.",
  "nastrelovaninausnic.co2": "Na trhu působí již více než 15 let a dodává své produkty do více než 60 zemí světa.",
  "nastrelovaninausnic.co3": "Je špičkou na světovém trhu v oblasti kvality, zpracování a nabídky barev a designů piercingových šperků.",
  "nastrelovaninausnic.co4": "Produkty značky Estelle se vyznačují bezkonkurenční kvalitou, která splňuje vysoké technikcé požadavky a nároky odborníků a zároveň klade důraz na designové provedení, škálu barev a vzorů požadovaných ze strany zákazníků.",
  "nastrelovaninausnic.scim": "S čím pracuji?",
  "nastrelovaninausnic.scim1": "Používám nejnovější piercingový systém, který klade důraz zejména na sterilitu.",
  "nastrelovaninausnic.scim2": "Splňuje veškeré nároky dle hygienických směrnic ČR a EU.",
  "nastrelovaninausnic.scim3": "Náušnice Estelle jsou vyrobeny z leštěné bílé oceli a chirurgické oceli pozlacené dvaceti čtyř karátovým žlutým zlatem o rizosti 999/1000 (pozlacené jsou i puzety).",
  "nastrelovaninausnic.scim4": "Neobsahují NIKL!!!",
  "nastrelovaninausnic.scim5": "Nášnice jsou osazeny nádhernými kamínky z kolekce Swarovski Elements.",
  "nastrelovaninausnic.scim6": "Vzorník nabízených náušnic je k dispozici u mě ve studiu.",
  "nastrelovaninausnic.jak": "Jak nastřelování probíhá?",
  "nastrelovaninausnic.jak1": "Nejprve jsou řádně zkontrolovány ušní boltce a po následné konzultaci je pomocí medicínského fixu naznačeno místo nastřelení.",
  "nastrelovaninausnic.jak2": "Poté se ucho dezinfikuje a nastřelí samotný šperk do vyznačeného místa nastřelovací pistolí.",
  "nastrelovaninausnic.jak3": "Upozorňuji, že nastřeluji pouze do měkkých částí ucha (ne do chrupavky).",
  "nastrelovaninausnic.jak4": "Po nastřelení se zkontroluje stav vpichu a uzávěr náušnice.",
  "nastrelovaninausnic.jak5": "Před odchodem ze studia je klientka řádně poučena o správné péči, možných komplikacích a následné výměně. To vše jí je předáno i v tištěné podobě.",
  "nastrelovaninausnic.jak6": "Náusnice Estelle jsou určeny i k trvalému nošení a není nutné je měnit za jiné.",
  "nastrelovaninausnic.kdy": "Kdy je vhodné náušnice nastřelit?",
  "nastrelovaninausnic.kdy1": "Náušnice je vhodné nastřelit dětem zhruba od čtyř měsíců věku (jsou již dobře vyvinuté ušní lalůčky).",
  "nastrelovaninausnic.kdy2": "Nastřelení nemá návaznost na očkování, veškerý materiál je sterilní a není proto nutné očkování absolvovat.",
  "nastrelovaninausnic.kdy3": "Nastřelení může podstoupit každý po osmnáctém roku věku.",
  "nastrelovaninausnic.kdy4": "Pokud klientce není ještě 18 let, musí být nastřelení provedeno za přítomnosti a souhlasu plnoletého.",
  "nastrelovaninausnic.pro": "Komu není vhodné náušnice nastřelit?",
  "nastrelovaninausnic.kontraindikace1": "dětem do čtyř měsíců věku",
  "nastrelovaninausnic.kontraindikace2": "při výskytu útvaru, který na ucho nepatří (tj. výrůstek, ucpaná mazová žláza, bradavice)",
  "nastrelovaninausnic.kontraindikace3": "lidem trpícím poruchou krevní srážlivosti",
  "nastrelovaninausnic.kontraindikace4": "při akutním onemocnění, nachlazení, teplotě atd.",
  "nastrelovaninausnic.kontraindikace5": "pacientům užívajícím léky na ředění krve",
  "nastrelovaninausnic.kontraindikace6": "při alergiích na chirurgickou ocel či zlato",
  "nastrelovaninausnic.kontraindikace7": "pacientům užívajícím antibiotika",
  "nastrelovaninausnic.kolik": "Kolik to stojí?",
  "nastrelovaninausnic.anti1": "1x Náušnice CHIRURGICKÁ OCEL (ANTIALERGENNÍ)",
  "nastrelovaninausnic.anti2": "500 Kč",
  "nastrelovaninausnic.zlato1": "1x Náušnice CHIRURGICKÁ OCEL POZLACENÁ 24KT ZLATEM",
  "nastrelovaninausnic.zlato2": "500 Kč",
  "nastrelovaninausnic.swarovski": "BEZ KAMÍNKU nebo S KAMÍNKEM SWAROVSKI",

  /** Ceník */
  "cenik.title": "Ceník",
  "cenik.description": "V ceníku najdete ceny například za permanentní obočí, korekce nebo aktuální slevy a akce.",
  "cenik.platny": "Ceník platný",
  "cenik.od": "od",

  /** Dárkové poukazy */
  "darkovepoukazy.title": "Dárkové poukazy",
  "darkovepoukazy.description": "Pokud chcete zakoupit dárkový poukaz neboli voucher na permanentní make-up a to na pudrové obočí, stínované rty, oční linky nebo nastřelení náušnice, volejte na telefonní číslo 728 313 090.",
  "darkovepoukazy.text1": "Dárkové poukazy na <strong>pudrové obočí</strong>, <strong>rty</strong>, <strong>linky v řasové linii</strong> a <strong>nastřelení náušnic</strong> u mě můžete zakoupit po celý rok.",
  "darkovepoukazy.text2": "Děkuji za Vaši důvěru a zakoupení poukázky na mé služby.",
  "darkovepoukazy.text3": "Podmínky pro použití dárkového poukazu",
  "darkovepoukazy.text4": "Platnost všech poukázek je 5 měsíců od data zakoupení.",
  "darkovepoukazy.text5": "Pokud se objednáte a budete chtít termín procedury změnit, musíte tak učinit minimálně 48 hodin před smluveným termínem. Jestliže tak neučiníte, bude Vám účtována částka 500 Kč.",
  "darkovepoukazy.text6": "V ceně poukázky <strong>NENÍ ZAHRNUTA</strong> cena korekce. Je tomu tak proto, že korekce nemusí být vždy potřeba.",
  "darkovepoukazy.text7": "Pokud nebudete moci poukázku z jakéhokoliv důvodu využít nebo Vám nebude moci být služba provedena (např. ze zdravotních důvodů), můžete ji darovat nebo prodat. Peníze za poukázky nevracím.",
  "darkovepoukazy.text8": "Poukázku musíte předložit při smluvené návštěvě, jinak Vám bude služba účtována a peníze vráceny až po předložení platné poukázky.",
  "darkovepoukazy.text9": "I v případě, že máte dárkový poukaz, respektujte, prosím, objednávací lhůty. Dárkový poukaz neznamená dřívější termín.",
  "darkovepoukazy.text10": "A pokud na proceduru nedorazíte bez předchozí omluvy, bude Vám při domluvě nového termínu účtován poplatek 1000 Kč, který uhradíte předem na účet.",
  "darkovepoukazy.text11": "Ukončení platnosti STARÝCH poukazů",
  "darkovepoukazy.text12": "Milé zákaznice, ukončuji platnost starých poukazů, tzn. poukazů zakoupených před 1. 4. 2021.",
  "darkovepoukazy.text13": "Všem děkuji za pochopení.",

  /** Kontakt */
  "kontakt.title": "Kontakt",
  "kontakt.description": "Veronika Zelenková jako Permanentní make-up Veronika Teplice se sídlem ve Studio Pano, Masarykova tř. 2475/57, 415 01 Teplice, Ústecký kraj.",
  "kontakt.ic": "IČ:",
  "kontakt.fyzicka": "Fyzická osoba podnikající dle živnostenského zákona",
  "kontakt.otevrit": "Otevřít v Google mapách",
  "kontakt.telefon": "Telefon",
  "kontakt.objednani": "Pro objednání volejte od pondělí do pátku, 9:00 - 17:00",
  "kontakt.jmeno": "Jméno",
  "placeholder.jmeno": "Vyplňte Vaše jméno",
  "chyba.jmeno": "Vyplňte, prosím, Vaše jméno",
  "placeholder.telefon": "Vyplňte Váš telefon",
  "chyba.telefon": "Vyplňte, prosím, Váš telefon",
  "chyba.pattern": "Vyplňte, prosím, platný telefon",
  "kontakt.dotaz": "Dotaz",
  "placeholder.dotaz": "Vyplňte Váš dotaz",
  "chyba.dotaz": "Vyplňte, prosím, Váš dotaz",
  "kontakt.odeslat": "Odeslat",

  /** Formulář odeslán */
  "formularodeslan.title": "Formulář odeslán",
  "formularodeslan.text1": "Formulář byl úspěšně odeslán",
  "formularodeslan.text2": "Děkuji za Váš dotaz, ozvu se Vám co nejdříve.",

  /** 404 */
  "error.title": "Stránka nenalezena",
  "error.text": "Stránka nebyla nenalezena",

  /** Cookies */
  "cookies.kliknutim": "Kliknutím na",
  "cookies.povolit": "Povolit vše",
  "cookies.text": "nám umožníte použití cookies pro funkční, analytické a marketingové účely na tomto zařízení. S Vaším souhlasem budeme používat cookies, díky kterým lépe poznáme, co Vás zajímá. Budeme Vám ukazovat informace, které hledáte a nebudeme Vás obtěžovat těmi ostatními. Jak na našem webu, tak i v reklamě na internetu. Na našem webu používáme i cookies, které jsou nezbytné, aby stránky fungovaly správně a rychleji jste se na nich zorientovali.",
  "cookies.zakazat": "Zakázat vše"
};

export type LanguageStrings = typeof baseStrings;
export const cs = baseStrings;
