import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Image } from "modules/layout";
import ReactImageVideoLightbox from "react-image-video-lightbox";

export const Video: React.FC<{lower: string, text: string, count: number, left?: boolean}> = ({ lower, text, count, left = true }) => {
  const [open, setOpen] = useState(false),
        [currentIndex, setCurrentIndex] = useState(0),
        images = [];

  const openLightbox = (index:any) => {
    setCurrentIndex(index);
    setOpen(true);
  };

  const intl = useIntl();

  for (let i = 1; i <= count; i++) {
    images.push(
      {
        url: require(`../../../themes/frontend/video/${lower}/${i}.mp4`).default,
        thumb: `video/${lower}/${i}`,
        title: `${text}`,
        type: "video"
      }
    );
  }

  const handleClick = (index: number) => () => openLightbox(index);

  const handleClose = () => setOpen(false);

  return (
    <section id="video" className="padding">
      <div className="container">
        <h2 className="sr-only"><FormattedMessage id="data.video" /></h2>
        <div className="gallery">
          <div className={`section-content padding ${ left ? "left" : "right" }`} data-title={intl.formatMessage({ id: "data.video" })}>
            {images.map((_im, idx) =>
              <span className="gallery-image" key={idx} onClick={handleClick(idx)}>
                <span className="gallery-link video">
                  <Image src={_im.thumb} alt={_im.title} width={550} height={550} lazy={false} />
                </span>
              </span>
            )}
            {open && (
              <ReactImageVideoLightbox
                data={images}
                startIndex={currentIndex}
                onCloseCallback={handleClose}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
