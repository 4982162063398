export const Arrow: React.FC<{url: string, text: string}> = ({ url, text }) => {
  const onClick = (e: any) => {
    e.preventDefault();
    const bodyRect = document.body.getBoundingClientRect(),
        elemRect = e.target.getBoundingClientRect(),
        offset   = elemRect.top - bodyRect.top;
    window.scrollTo({top: offset - document.getElementsByTagName("header")[0].offsetHeight + e.target.offsetHeight, behavior: "smooth"});
  };

  return (   
    <a className="arrow" href={url} title={text} onClick={onClick}>{text}</a>
  );
};
