import { Meta, Images, SectionContent, Arrow, replaceSpecialChars, Accordion } from "modules/layout";
import { FormattedMessage, useIntl } from "react-intl";

export const Cenik: React.FC = () => {
  const intl = useIntl();

  return (
    <>
      <Meta title={intl.formatMessage({ id: "cenik.title" })} description={intl.formatMessage({ id: "cenik.description" })} />
      <section className="padding">
        <Images />
        <SectionContent heading={intl.formatMessage({ id: "cenik.title" })} />
        <Arrow url={"#"+replaceSpecialChars(intl.formatMessage({ id: "cenik.title" }))} text={intl.formatMessage({ id: "cenik.title" })} />
      </section>
      <section id={replaceSpecialChars(intl.formatMessage({ id: "cenik.title" }))} className="padding">
        <div className="container">
          <div className="section-content left padding" data-title={intl.formatMessage({ id: "data.dulezite" })}>
            <h2><FormattedMessage id="cenik.platny" /> <span className="no-wrap"><FormattedMessage id="cenik.od" /> 1. 4. 2023</span></h2>
            <ul>
              <li className="accordion has-pricelist">
                <span onClick={Accordion}><FormattedMessage id="pudroveoboci.title" /></span>
                <ul className="panel">
                  <li className="pricelist">
                    <ul className="pricelist-inner">
                      <li className="pricelist-row">
                        <span><FormattedMessage id="pudroveoboci.title" /></span>
                        <span><strong><FormattedMessage id="pudroveoboci.cena" /></strong></span>
                      </li>
                      <li className="pricelist-row">
                        <span><FormattedMessage id="data.oziveni" /></span>
                        <span><strong><FormattedMessage id="pudroveoboci.oziveni" /></strong><FormattedMessage id="data.pouze" /></span>
                      </li>
                    </ul>
                  </li>
                  <li><FormattedMessage id="data.zahrnuje" />
                    <ul>
                      <li><FormattedMessage id="pudroveoboci.zahrnuje1" /></li>
                      <li><FormattedMessage id="pudroveoboci.zahrnuje2" /></li>
                      <li><FormattedMessage id="pudroveoboci.zahrnuje3" /></li>
                      <li><FormattedMessage id="pudroveoboci.zahrnuje4" /></li>
                      <li><FormattedMessage id="pudroveoboci.zahrnuje5" /></li>
                      <li><FormattedMessage id="pudroveoboci.zahrnuje6" /></li>
                      <li><FormattedMessage id="pudroveoboci.zahrnuje7" /></li>
                      <li><FormattedMessage id="pudroveoboci.zahrnuje8" /></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="accordion has-pricelist">
                <span onClick={Accordion}><FormattedMessage id="rty.title" /></span>
                <ul className="panel">
                  <li className="pricelist">
                    <ul className="pricelist-inner">
                      <li className="pricelist-row">
                        <span>Aquarelle</span>
                        <span><strong><FormattedMessage id="rty.cena1" /></strong></span>
                      </li>
                      <li className="pricelist-row">
                        <span>Fullshading</span>
                        <span><strong><FormattedMessage id="rty.cena2" /></strong></span>
                      </li>
                      <li className="pricelist-row">
                        <span>Nude Lips</span>
                        <span><strong><FormattedMessage id="rty.cena3" /></strong></span>
                      </li>
                      <li className="pricelist-row">
                        <span><FormattedMessage id="data.oziveni" /></span>
                        <span><strong><FormattedMessage id="rty.oziveni" /></strong><FormattedMessage id="data.pouze" /></span>
                      </li>
                    </ul>
                  </li>
                  <li><FormattedMessage id="data.zahrnuje" />
                    <ul>
                      <li><FormattedMessage id="rty.zahrnuje1" /></li>
                      <li><FormattedMessage id="rty.zahrnuje2" /></li>
                      <li><FormattedMessage id="rty.zahrnuje3" /></li>
                      <li><FormattedMessage id="rty.zahrnuje4" /></li>
                      <li><FormattedMessage id="rty.zahrnuje5" /></li>
                      <li><FormattedMessage id="rty.zahrnuje6" /></li>
                      <li><FormattedMessage id="rty.zahrnuje7" /></li>
                      <li><FormattedMessage id="rty.zahrnuje8" /></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="accordion has-pricelist">
                <span onClick={Accordion}><FormattedMessage id="ocnilinky.title" /></span>
                <ul className="panel">
                  <li className="pricelist">
                    <ul className="pricelist-inner">
                      <li className="pricelist-row">
                        <span><FormattedMessage id="ocnilinky.natural" /></span>
                        <span><strong><FormattedMessage id="ocnilinky.cena1" /></strong></span>
                      </li>
                      <li className="pricelist-row">
                        <span><FormattedMessage id="ocnilinky.shading" /></span>
                        <span><strong><FormattedMessage id="ocnilinky.cena2" /></strong></span>
                      </li>
                    </ul>
                  </li>
                  <li><FormattedMessage id="data.zahrnuje" />
                    <ul>
                      <li><FormattedMessage id="ocnilinky.zahrnuje1" /></li>
                      <li><FormattedMessage id="ocnilinky.zahrnuje2" /></li>
                      <li><FormattedMessage id="ocnilinky.zahrnuje3" /></li>
                      <li><FormattedMessage id="ocnilinky.zahrnuje4" /></li>
                      <li><FormattedMessage id="ocnilinky.zahrnuje5" /></li>
                      <li><FormattedMessage id="ocnilinky.zahrnuje6" /></li>
                      <li><FormattedMessage id="ocnilinky.zahrnuje7" /></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="accordion has-pricelist">
                <span onClick={Accordion}><FormattedMessage id="miniaquarelletattoo.title" /></span>
                <ul className="panel">
                  <li className="pricelist">
                    <ul className="pricelist-inner">
                      <li><FormattedMessage id="miniaquarelletattoo.cena" /></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="accordion has-pricelist">
                <span onClick={Accordion}><FormattedMessage id="odstranenipmu.title" /></span>
                <ul className="panel">
                  <li className="pricelist">
                    <ul className="pricelist-inner">
                      <li className="pricelist-row">
                        <span><FormattedMessage id="odstranenipmu.sezeni" /></span>
                        <span><strong><FormattedMessage id="odstranenipmu.cena1" /></strong></span>
                      </li>
                      <li><FormattedMessage id="odstranenipmu.cena2" /></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="accordion has-pricelist">
                <span onClick={Accordion}><FormattedMessage id="nastrelovaninausnic.title" /></span>
                <ul className="panel">
                  <li className="pricelist">
                    <ul className="pricelist-inner">
                      <li className="pricelist-row">
                        <span><FormattedMessage id="nastrelovaninausnic.anti1" /><br /><FormattedMessage id="nastrelovaninausnic.swarovski" /></span>
                        <span><strong><FormattedMessage id="nastrelovaninausnic.anti2" /></strong></span>
                      </li>
                      <li className="pricelist-row">
                        <span><FormattedMessage id="nastrelovaninausnic.zlato1" /><br /><FormattedMessage id="nastrelovaninausnic.swarovski" /></span>
                        <span><strong><FormattedMessage id="nastrelovaninausnic.zlato2" /></strong></span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <br /><br />
            <h3><FormattedMessage id="data.neprehlednete" /></h3>
            <ul>
              <li><FormattedMessage id="data.neprehlednete1" /></li>
              <li><FormattedMessage id="data.neprehlednete2" /></li>
              <li><FormattedMessage id="data.neprehlednete3" /></li>
              <li><FormattedMessage id="data.neprehlednete4" /></li>
              <li><FormattedMessage id="data.neprehlednete5" /></li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};
